import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Star, BookOpen } from 'lucide-react';
import axios from 'axios';
import './fonts.css';

const LeaderboardTab = ({ label, isActive, onClick }) => (
  <button
    className={`px-6 py-3 rounded-lg text-sm font-medium transition-colors ${
      isActive 
        ? 'bg-blue-500 text-white' 
        : 'bg-white text-gray-600 hover:bg-gray-50'
    }`}
    onClick={onClick}
  >
    {label}
  </button>
);

const UserRow = ({ rank, user, activeTab }) => {
  const getRankStyle = (rank) => {
    if (rank === 1) return "bg-yellow-400 text-white";
    if (rank === 2) return "bg-gray-400 text-white";
    if (rank === 3) return "bg-orange-400 text-white";
    return "bg-gray-200 text-gray-500";
  };

  return (
    <div className="flex items-center justify-between py-4 border-b">
      <div className="flex items-center flex-1">
        <span className={`w-8 h-8 flex items-center justify-center rounded-full font-bold ${getRankStyle(rank)}`}>
          {rank}
        </span>
        <img src={`${user.avatar}`} alt={user.username} className="w-12 h-12 rounded-full ml-4 object-cover" />
        <div className="ml-4">
          <h3 className="font-semibold font-poppins">{user.username}</h3>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex items-center bg-blue-100 px-3 py-1 rounded-full">
          {activeTab === 'Word Challenge' ? (
            <>
              <Star size={18} className="text-blue-500 mr-2" />
              <span className="font-inter font-semibold text-blue-700">{user.wordChallengePoints}</span>
            </>
          ) : (
            <>
              <BookOpen size={18} className="text-blue-500 mr-2" />
              <span className="font-inter font-semibold text-blue-700">{user.completedLessons}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const LeaderboardPage = () => {
  const [activeTab, setActiveTab] = useState('Word Challenge');
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/api/leaderboard', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setLeaderboard(response.data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch leaderboard data');
      }
      setIsLoading(false);
    };

    fetchLeaderboard();
  }, []);

  const sortedLeaderboard = [...leaderboard].sort((a, b) => 
    activeTab === 'Word Challenge' 
      ? b.wordChallengePoints - a.wordChallengePoints
      : b.completedLessons - a.completedLessons
  );

  return (
    <div className="space-y-12 bg-gray-50 p-8 rounded-lg font-inter">
      <section>
        <h1 className="text-3xl font-bold mb-6 text-gray-900 font-poppins">Leaderboard</h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex space-x-4 mb-6">
            {['Word Challenge', 'Lesson'].map((tab) => (
              <LeaderboardTab
                key={tab}
                label={tab}
                isActive={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              />
            ))}
          </div>
          {isLoading ? (
            <p>Loading leaderboard...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <div>
              <div className="flex items-center justify-between mb-4 font-semibold text-gray-600">
                <span className="flex-1">User</span>
                <span>{activeTab === 'Word Challenge' ? 'Score' : 'Completed Lessons'}</span>
              </div>
              {sortedLeaderboard.map((user, index) => (
                <UserRow key={user.id} rank={index + 1} user={user} activeTab={activeTab} />
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default LeaderboardPage;