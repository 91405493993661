import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WordNotesList from './WordNotesList';
import WordNoteContent from './WordNoteContent';

function WordNotes() {
  return (
    <Routes>
      <Route index element={<WordNotesList />} />
      <Route path=":noteId" element={<WordNoteContent />} />
    </Routes>
  );
}

export default WordNotes;