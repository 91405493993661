import React, { useState, useEffect } from 'react';
import { CheckCircle, Clock, Award, X, Edit2, Calendar } from 'lucide-react';
import axios from 'axios';
import { parseISO, format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday } from 'date-fns';

const StatCard = ({ icon: Icon, title, value, color, textColor }) => (
  <div className={`${color} rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform hover:scale-105`}>
    <Icon className={`${textColor} mb-4`} size={48} />
    <h3 className={`text-4xl font-bold ${textColor} mb-2`}>{value}</h3>
    <p className={`text-lg font-semibold ${textColor} opacity-90`}>{title}</p>
  </div>
);

const AvatarModal = ({ isOpen, onClose, onSelect, avatars }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Select Avatar</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="grid grid-cols-4 gap-4">
          {avatars.map((avatar, index) => (
            <img
              key={index}
              src={`${avatar}`}
              alt={`Avatar ${index + 1}`}
              className="w-16 h-16 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500"
              onClick={() => onSelect(avatar)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const LoginActivityCalendar = ({ loginDates }) => {
  const today = new Date();
  const start = startOfMonth(today);
  const end = endOfMonth(today);
  const days = eachDayOfInterval({ start, end });

  const getLoginStatus = (date) => {
    return loginDates.some(loginDate => 
      loginDate.getDate() === date.getDate() &&
      loginDate.getMonth() === date.getMonth() &&
      loginDate.getFullYear() === date.getFullYear()
    );
  };

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="flex justify-between items-center mb-4">
        <button className="text-gray-600 hover:text-gray-800">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <h2 className="text-lg font-semibold text-gray-800">{format(today, 'MMMM yyyy')}</h2>
        <button className="text-gray-600 hover:text-gray-800">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-center">
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
          <div key={day} className="text-xs font-medium text-gray-500">{day}</div>
        ))}
        {days.map((day, dayIdx) => (
          <div
            key={day.toString()}
            className={`
              p-1 text-sm rounded-full
              ${!isSameMonth(day, today) ? 'text-gray-400' : 'text-gray-900'}
              ${isToday(day) ? 'bg-blue-100' : ''}
              ${getLoginStatus(day) ? 'bg-green-100' : ''}
            `}
          >
            {format(day, 'd')}
          </div>
        ))}
      </div>
    </div>
  );
};

const UserProfilePage = () => {
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [loginDates, setLoginDates] = useState([]);
  const [userStats, setUserStats] = useState({
    finishedCourses: 0,
    wordChallengePoints: 0,
  });

  useEffect(() => {
    fetchUserData();
    fetchAvatars();
    fetchLoginActivity();
    fetchUserStats();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('/api/user', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchAvatars = async () => {
    try {
      const response = await axios.get('/api/avatars');
      setAvatars(response.data);
    } catch (error) {
      console.error('Error fetching avatars:', error);
    }
  };

  const handleAvatarSelect = async (avatar) => {
    try {
      await axios.post('/api/user/avatar', { avatar }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUser({ ...user, avatar });
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  const handleUsernameChange = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('/api/user', { username: newUsername }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUser({ ...user, username: newUsername });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating username:', error);
    }
  };

  const fetchLoginActivity = async () => {
    try {
      const response = await axios.get('/api/user/login-activity', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setLoginDates(response.data.map(date => parseISO(date)));
    } catch (error) {
      console.error('Error fetching login activity:', error);
    }
  };

  const fetchUserStats = async () => {
    try {
      const response = await axios.get('/api/user/stats', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUserStats(response.data);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  };

  if (!user) return <div>Loading...</div>;

  return (
    <div className="space-y-12 bg-gray-50 p-8 rounded-lg font-inter">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="bg-white rounded-lg shadow-md p-8 flex-grow">
          <div className="flex items-center space-x-6">
            <img
              src={`${user.avatar}`}
              alt="User avatar"
              className="w-32 h-32 rounded-full cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            />
            <div className="flex-grow">
              {isEditing ? (
                <form onSubmit={handleUsernameChange} className="flex items-center">
                  <input
                    type="text"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    className="text-3xl font-bold text-gray-900 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                  />
                  <button type="submit" className="ml-2 text-blue-500 hover:text-blue-600">
                    <CheckCircle size={24} />
                  </button>
                </form>
              ) : (
                <div className="flex items-center">
                  <h1 className="text-3xl font-bold text-gray-900">{user.username}</h1>
                  <button onClick={() => {
                    setIsEditing(true);
                    setNewUsername(user.username);
                  }} className="ml-2 text-gray-500 hover:text-gray-700">
                    <Edit2 size={20} />
                  </button>
                </div>
              )}
              <p className="text-gray-600">{user.email}</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-8 w-full md:w-96">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Calendar size={20} className="mr-2" />
            Login Activity
          </h3>
          <LoginActivityCalendar loginDates={loginDates} />
        </div>
      </div>

      <section className="mt-12">
        <h2 className="text-3xl font-extrabold mb-8 text-gray-800 text-center">Your Learning Journey</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <StatCard 
            icon={CheckCircle} 
            title="Finished Courses" 
            value={userStats.completedLessons} 
            color="bg-blue-100" 
            textColor="text-blue-600"
          />
          <StatCard 
            icon={Award} 
            title="Word Challenge Points" 
            value={userStats.wordChallengePoints} 
            color="bg-green-100" 
            textColor="text-green-600"
          />
        </div>
      </section>

      <AvatarModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelect={handleAvatarSelect}
        avatars={avatars}
      />
    </div>
  );
};

export default UserProfilePage;