import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import axios from 'axios';

const SummaryContainer = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  font-family: 'Comic Sans MS', cursive, sans-serif;
`;

const SummaryTitle = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: #4b0082;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const SummaryItem = styled.div`
  margin-bottom: 20px;
  font-size: 1.3rem;
  color: #4b0082;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MetricValue = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.perfect ? '#4CAF50' : '#ff6b6b'};
  background-color: ${props => props.perfect ? '#E8F5E9' : '#fff0f0'};
  padding: 5px 15px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(255, 107, 107, 0.2);
`;

const ErrorWordsContainer = styled.div`
  background-color: #f0e6fa;
  border-radius: 15px;
  padding: 15px;
  margin-top: 20px;
`;

const ErrorWordItem = styled.div`
  background-color: #fff0f0;
  border: 1px solid #ffcccb;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const WordComparison = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Word = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Translation = styled.p`
  font-size: 0.9rem;
  color: #4b0082;
  margin-bottom: 5px;
  font-family: 'Noto Sans SC', 'Yuanti SC', 'Yuanti TC', 'Microsoft YaHei', 'PingFang SC', sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;

const Phonetic = styled.p`
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
`;

const AllPassContainer = styled.div`
  background-color: #E8F5E9;
  border-radius: 15px;
  padding: 15px;
  margin-top: 20px;
  text-align: center;
  color: #4CAF50;
  font-size: 1.5rem;
  font-weight: bold;
`;

const AddToNoteButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 15px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const AddToNoteHint = styled.div`
  background-color: #E8F5E9;
  color: #4CAF50;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LevelSummary = ({ gameState, categoryId, onAddToWordNote }) => {
  const [isAddingToNote, setIsAddingToNote] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const errorWords = gameState.userAnswers
    .filter(answer => !answer.isCorrect)
    .map(answer => {
      const question = gameState.questions[answer.question];
      return {
        correctWord: question.word,
        userInput: answer.answer,
        translation: question.translation,
        phonetic: question.phonetic,
        partOfSpeech: question.partOfSpeech,
        imageUrl: question.imageUrl,
        audioUrl: question.audioUrl,
        tips: question.tips
      };
    });

  const totalWords = gameState.questions.length;
  const correctWords = totalWords - errorWords.length;
  const accuracy = (correctWords / totalWords) * 100;

  const totalTime = gameState.userAnswers.reduce((sum, answer) => {
    const questionTime = 200 - answer.timeLeft;
    return sum + questionTime;
  }, 0);

  const averageTimePerWord = totalTime / gameState.questions.length;

  const isPerfectScore = accuracy === 100;

  const handleAddToWordNote = () => {
    setIsAddingToNote(true);
    onAddToWordNote();
    setShowHint(true);
    setTimeout(() => {
      setIsAddingToNote(false);
    }, 2000);
  };

  return (
    <SummaryContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <SummaryTitle>Level Summary</SummaryTitle>
      <SummaryItem>
        <span>Accuracy:</span>
        <MetricValue perfect={isPerfectScore}>{accuracy.toFixed(1)}%</MetricValue>
      </SummaryItem>
      <SummaryItem>
        <span>Time to complete:</span>
        <MetricValue>{(totalTime / 10).toFixed(1)}s</MetricValue>
      </SummaryItem>
      <SummaryItem>
        <span>Average time per word:</span>
        <MetricValue>{(averageTimePerWord / 10).toFixed(1)}s</MetricValue>
      </SummaryItem>
      {isPerfectScore ? (
        <AllPassContainer>
          All Pass! Great job!
        </AllPassContainer>
      ) : (
        <ErrorWordsContainer>
          <AddToNoteButton onClick={handleAddToWordNote} disabled={isAddingToNote}>
            {isAddingToNote ? 'Adding...' : 'Add to Word Note 📝'}
          </AddToNoteButton>
          {showHint && (
            <AddToNoteHint>
              Words added! Find them in: Home → Word Notes → Word Challenge
            </AddToNoteHint>
          )}
          <SummaryItem style={{ marginBottom: '10px' }}>
            <strong>Error words:</strong>
            <span>{errorWords.length}/{totalWords}</span>
          </SummaryItem>
          {errorWords.map((word, index) => (
            <ErrorWordItem key={index}>
              <WordComparison>
                <Word style={{ color: 'green' }}>{word.correctWord}</Word>
                <Word style={{ color: 'red' }}>{word.userInput}</Word>
              </WordComparison>
              <Translation>{word.translation}</Translation>
              <Phonetic>[{word.phonetic}]</Phonetic>
            </ErrorWordItem>
          ))}
        </ErrorWordsContainer>
      )}
    </SummaryContainer>
  );
};

export default LevelSummary;