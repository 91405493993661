import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Switch, TextField, Card, CardContent, FormControlLabel, Button,
  Radio, RadioGroup, FormControl, FormLabel, Alert
} from '@mui/material';
import AudioPlayer from './AudioPlayer';

const ListeningModule = ({ data }) => {
  const [showScript, setShowScript] = useState(true);
  const [userAnswers, setUserAnswers] = useState({});
  const [showAnswers, setShowAnswers] = useState(false);
  const [blankSizes, setBlankSizes] = useState({});
  const [showResults, setShowResults] = useState(false);

  const splitContent = (content) => {
    return content.split(/(___|\s+|[.,?!])/);
  };

  useEffect(() => {
    const initialAnswers = {};
    const initialBlankSizes = {};
    data.script.forEach((line, lineIndex) => {
      if (line.blank_content) {
        const parts = splitContent(line.blank_content);
        parts.forEach((part, partIndex) => {
          if (part === '___') {
            const key = `${lineIndex}-${partIndex}`;
            initialAnswers[key] = '';
            initialBlankSizes[key] = 100; // Default width
          }
        });
      }
    });
    
    setUserAnswers(initialAnswers);
    setBlankSizes(initialBlankSizes);
  }, [data.script]);

  const handleAnswerChange = (lineIndex, partIndex, value) => {
    const key = `${lineIndex}-${partIndex}`;
    setUserAnswers(prev => ({
      ...prev,
      [key]: value
    }));
    setBlankSizes(prev => ({
      ...prev,
      [key]: Math.max(value.length, 10) * 10
    }));
  };

  const toggleScript = () => {
    setShowScript(!showScript);
  };

  const toggleAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  const handleOptionChange = (questionIndex, value) => {
    setUserAnswers(prev => ({
      ...prev,
      [questionIndex]: value
    }));
  };

  const handleSubmit = () => {
    setShowResults(true);
  };

  const renderScript = () => {
    return data.script.map((line, lineIndex) => (
      <Box key={lineIndex} sx={{ mb: 1 }}>
        <strong>{line.role_name}:</strong>{' '}
        {(() => {
          if (!line.blank_content) return line.content;
          
          const parts = splitContent(line.blank_content);
          return parts.map((part, partIndex) => {
            if (part === '___') {
              const key = `${lineIndex}-${partIndex}`;
              const correctWord = line.answer || '';
              const userAnswer = userAnswers[key] || '';
              const isCorrect = (userAnswer.trim().toLowerCase() === correctWord.trim().toLowerCase()) && correctWord !== '';
              return (
                <React.Fragment key={key}>
                  {showAnswers && (
                    <Typography
                      component="span"
                      sx={{
                        color: '#2e7d32',
                        backgroundColor: '#e8f5e9',
                        padding: '2px 4px',
                        borderRadius: '4px',
                        marginRight: '8px',
                      }}
                    >
                      {correctWord}
                    </Typography>
                  )}
                  <TextField
                    variant="standard"
                    size="small"
                    value={userAnswer}
                    onChange={(e) => handleAnswerChange(lineIndex, partIndex, e.target.value)}
                    sx={{ 
                      width: `${blankSizes[key] || 100}px`,
                      mx: 1,
                      transition: 'width 0.3s',
                      '& .MuiInput-root': {
                        color: isCorrect ? '#2e7d32' : '#d32f2f',
                        backgroundColor: isCorrect ? '#e8f5e9' : '#ffebee',
                      }
                    }}
                  />
                </React.Fragment>
              );
            }
            return part;
          });
        })()}
      </Box>
    ));
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>Listening Exercise</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showAnswers}
              onChange={() => setShowAnswers(!showAnswers)}
              color="primary"
            />
          }
          label={showAnswers ? "Hide Answers" : "Show Answers"}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showScript}
              onChange={() => setShowScript(!showScript)}
              color="primary"
            />
          }
          label={showScript ? "Hide Script" : "Show Script"}
        />
      </Box>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          {data.audio_url ? (
            <AudioPlayer src={data.audio_url} />
          ) : (
            <Typography color="error">Audio file not available</Typography>
          )}
          {showScript && (
            <>
              <Typography variant="h6" gutterBottom>Script:</Typography>
              <Box sx={{ mb: 2 }}>{renderScript()}</Box>
            </>
          )}
        </CardContent>
      </Card>

      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>Questions</Typography>
      {data.questions.map((question, questionIndex) => (
        <Box key={questionIndex} mt={4}>
          <Typography variant="subtitle1" gutterBottom>
            {questionIndex + 1}. {question.question}
          </Typography>
          <RadioGroup
            value={userAnswers[questionIndex] || ''}
            onChange={(e) => handleOptionChange(questionIndex, e.target.value)}
          >
            {question.options.map((option, optionIndex) => (
              <FormControlLabel
                key={optionIndex}
                value={optionIndex.toString()}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
          {showResults && (
            <Alert 
              severity={
                userAnswers[questionIndex] === question.correctAnswer.toString() 
                  ? "success" 
                  : "error"
              } 
              sx={{ mt: 2 }}
            >
              {userAnswers[questionIndex] === question.correctAnswer.toString()
                ? 'Correct!' 
                : `Incorrect. The correct answer is: ${question.options[question.correctAnswer]}`
              }
            </Alert>
          )}
        </Box>
      ))}

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSubmit} 
        sx={{ mt: 4 }}
        disabled={showResults}
      >
        Submit All Answers
      </Button>
    </>
  );
};

export default ListeningModule;