import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import Layout from './components/Layout';
import LandingPage from './components/LandingPage';
import HomePage from './components/HomePage';
import RecordPage from './components/Record/RecordPage';
import CategorySelect from './components/WordChallenge/CategorySelect';
import Game from './components/WordChallenge/Game';
import LeaderboardPage from './components/LeaderboardPage';
import SettingsPage from './components/SettingsPage';
import Login from './components/Auth/Login';
import UserProfile from './components/UserProfile';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import LessonModule from './components/Lesson/LessonModule';
import UserProfilePage from './components/UserProfile';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import EmailVerification from './components/Auth/EmailVerification';
import NotFoundPage from './components/NotFoundPage';
import WordNotes from './components/WordChallenge/word_notes/WordNotes';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route element={<Layout />}>
            <Route path="/verify-email/:token" element={<AuthenticatedRoute element={EmailVerification} requireAuth={true} />} />
            <Route path="/home" element={<AuthenticatedRoute element={HomePage} requireAuth={true} />} />
            <Route path="word-challenge" element={<AuthenticatedRoute element={CategorySelect} requireAuth={true} />} />
            <Route path="word-challenge/:category/:subCategory?/game" element={<AuthenticatedRoute element={Game} requireAuth={true} />} />
            <Route path="record" element={<AuthenticatedRoute element={RecordPage} requireAuth={true} />} />
            <Route path="leaderboard" element={<AuthenticatedRoute element={LeaderboardPage} requireAuth={true} />} />
            <Route path="settings" element={<AuthenticatedRoute element={SettingsPage} requireAuth={true} />} />
            <Route path="profile" element={<AuthenticatedRoute element={UserProfilePage} requireAuth={true} />} />
            <Route path="lesson/*" element={<AuthenticatedRoute element={LessonModule} requireAuth={true} />} />
            <Route path="word-notes/*" element={<AuthenticatedRoute element={WordNotes} requireAuth={true} />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;