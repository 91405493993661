import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

const AbilitySummary = () => {
  const data = [
    { name: 'Vocabulary', score: 80 },
    { name: 'Grammar', score: 75 },
    { name: 'Pronunciation', score: 70 },
    { name: 'Listening', score: 85 },
    { name: 'Reading', score: 90 },
    { name: 'Writing', score: 65 },
    { name: 'Speaking', score: 60 },
    { name: 'Spelling', score: 85 },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Ability Summary</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} layout="vertical">
          <XAxis type="number" domain={[0, 100]} />
          <YAxis dataKey="name" type="category" width={100} />
          <Tooltip />
          <Bar dataKey="score" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AbilitySummary;
