import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import useSound from 'use-sound';
import axios from 'axios';
import { ChevronRight, RefreshCw, Volume2 } from 'lucide-react';
import typingSound from '../../data/audio_effect/keyboard-typing-one-character.mp3';

const typeAnimation = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const AnimatedInput = styled.input`
  font-family: 'Comic Neue', cursive;
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: lowercase;
  letter-spacing: 0.05em;
  color: #4a5568;
  text-shadow: 2px 2px 0px #ffffff, 3px 3px 0px rgba(0, 0, 0, 0.1);

  &::placeholder {
    overflow: hidden;
    white-space: nowrap;
    animation: ${typeAnimation} 2s steps(40, end);
  }
`;

const GameContainer = styled.div`
  font-family: 'Poppins', 'Inter', sans-serif;
`;

const ProgressContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #4299e1;
  border-radius: 4px;
  transition: width 0.3s ease;
`;

const GameLogic = ({ gameState, updateGameState, category, navigate }) => {
  const inputRef = useRef(null);
  const [playTypingSound] = useSound(typingSound, { volume: 0.5 });
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const [currentLevelProgress, setCurrentLevelProgress] = useState(0);
  const [showShortcutHint, setShowShortcutHint] = useState(false); // State for showing the shortcut hint

  useEffect(() => {
    const currentProgressPercentage = (gameState.currentQuestion / gameState.questions.length) * 100;
    setCurrentLevelProgress(currentProgressPercentage);
  }, [gameState.currentQuestion, gameState.questions.length]);

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const fetchQuestions = useCallback((level) => {
    const token = localStorage.getItem('token');
    updateGameState({ isLoading: true });
    axios.get(`/api/word-challenge?category_id=${category}&level=${level}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(async (response) => {
        if (response.data && Array.isArray(response.data.words)) {
          const words = response.data.words;
          const mediaPromises = words.map(word => 
            axios.get(`/api/word-challenge/media?word_id=${word.id}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
          );

          const mediaResponses = await Promise.all(mediaPromises);
          const preloadedMedia = {};

          for (let i = 0; i < words.length; i++) {
            const wordId = words[i].id;
            const mediaData = mediaResponses[i].data;
            const imageUrl = mediaData.image_url;
            const audioUrl = mediaData.audio_url;

            await preloadImage(imageUrl);
            preloadedMedia[wordId] = { image_url: imageUrl, audio_url: audioUrl };
          }

          updateGameState({
            questions: words,
            totalLevels: response.data.total_levels || 3,
            hasNextLevel: response.data.has_next_level,
            currentQuestion: 0,
            userAnswers: [],
            inputValue: '',
            showAnswer: false,
            isCorrect: null,
            timeLeft: 200,
            error: null,
            preloadedMedia,
            isLoading: false,
          });
        } else {
          throw new Error("Invalid data structure received from API");
        }
      })
      .catch(error => {
        console.error('Error fetching questions:', error);
        updateGameState({ 
          error: "Failed to load questions. Please try again later.",
          isLoading: false
        });
      });
  }, [category, updateGameState]);

  useEffect(() => {
    if (gameState.gameStarted && !gameState.gameOver) {
      fetchQuestions(gameState.currentLevel);
    }
  }, [gameState.currentLevel, gameState.gameStarted, gameState.gameOver, fetchQuestions]);

  useEffect(() => {
    if (gameState.timeLeft > 0 && !gameState.showAnswer && !gameState.isPaused) {
      const timer = setTimeout(() => updateGameState({ timeLeft: gameState.timeLeft - 1 }), 100);
      return () => clearTimeout(timer);
    } else if (gameState.timeLeft === 0 && !gameState.showAnswer) {
      handleSubmit();
    }
  }, [gameState.timeLeft, gameState.showAnswer, gameState.isPaused, updateGameState]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [gameState.currentQuestion]);

  const toggleAudio = (showHint = false) => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
      if (showHint) {
        setShowShortcutHint(true); // Show the shortcut hint when the audio button is clicked

        // Hide the hint after 3 seconds
        setTimeout(() => {
          setShowShortcutHint(false);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'e') {
        event.preventDefault();
        toggleAudio(false); // Do not show the hint when using the shortcut
      }
    };

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value.toLowerCase();
    updateGameState({ inputValue: newValue });
    
    if (isSoundEnabled) {
      playTypingSound();
    }
    
    // Move cursor to the end of input
    if (inputRef.current) {
      inputRef.current.selectionStart = newValue.length;
      inputRef.current.selectionEnd = newValue.length;
    }
  };

  const enableSound = () => {
    setIsSoundEnabled(true);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!gameState.questions[gameState.currentQuestion]) {
      console.error("No current question available");
      return;
    }

    const currentWord = gameState.questions[gameState.currentQuestion];
    
    try {
      const response = await axios.post(`/api/word-challenge/${currentWord.id}`, {
        answer: gameState.inputValue
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      const isAnswerCorrect = response.data.is_correct;
      const updatedUserAnswers = [...gameState.userAnswers, {
        question: gameState.currentQuestion,
        answer: gameState.inputValue,
        isCorrect: isAnswerCorrect,
        timeLeft: gameState.timeLeft // Add this line to store the remaining time
      }];
      const updatedScore = isAnswerCorrect ? gameState.score + 10 * (gameState.combo + 1) : gameState.score;
      const updatedCombo = isAnswerCorrect ? gameState.combo + 1 : 0;

      if (gameState.currentQuestion === gameState.questions.length - 1) {
        // This is the last question, calculate final results immediately
        const correctAnswers = updatedUserAnswers.filter(answer => answer.isCorrect).length;
        const totalQuestions = gameState.questions.length;
        const percentageCorrect = (correctAnswers / totalQuestions) * 100;
        
        let stars = 1;
        if (percentageCorrect >= 80) stars = 3;
        else if (percentageCorrect >= 60) stars = 2;

        updateGameState({
          isCorrect: isAnswerCorrect,
          showAnswer: true,
          score: updatedScore,
          combo: updatedCombo,
          userAnswers: updatedUserAnswers,
          gameOver: true,
          finalResults: {
            score: updatedScore,
            stars: stars,
            correctAnswers: correctAnswers,
            totalQuestions: totalQuestions,
          },
          currentWordDetails: {
            word: currentWord.word,
            translation: currentWord.translation,
            partOfSpeech: currentWord.partOfSpeech,
            phonetic: currentWord.phonetic
          }
        });
      } else {
        updateGameState({
          isCorrect: isAnswerCorrect,
          showAnswer: true,
          score: updatedScore,
          combo: updatedCombo,
          userAnswers: updatedUserAnswers,
          currentWordDetails: {
            word: currentWord.word,
            translation: currentWord.translation,
            partOfSpeech: currentWord.partOfSpeech,
            phonetic: currentWord.phonetic
          }
        });
      }
      
      const currentWordMedia = gameState.preloadedMedia[currentWord.id];
      if (currentWordMedia && currentWordMedia.audio_url) {
        const audio = new Audio(currentWordMedia.audio_url);
        audio.play();
      }

      setTimeout(() => handleNextWord(), 3000); // Increased delay to allow more time to read the details
    } catch (error) {
      console.error('Error submitting answer:', error);
      updateGameState({ error: "An error occurred while submitting your answer. Please try again." });
    }
  };

  const handleNextWord = () => {
    if (gameState.currentQuestion < gameState.questions.length - 1) {
      updateGameState({
        showAnswer: false,
        isCorrect: null,
        showHint: false,
        currentQuestion: gameState.currentQuestion + 1,
        inputValue: '',
        timeLeft: 200,
      });
    }
  };

  const handleHint = () => {
    updateGameState({
      showHint: true,
      inputValue: gameState.questions[gameState.currentQuestion].word.slice(0, 3).toLowerCase(),
    });
  };

  const handleStartGame = (selectedLevel) => {
    updateGameState({
      currentLevel: selectedLevel,
      gameStarted: true,
      gameOver: false,
      currentQuestion: 0,
      score: 0,
      timeLeft: 200,
      userAnswers: [],
      inputValue: '',
      combo: 0,
      showAnswer: false,
      isCorrect: null,
      showHint: false,
      finalResults: null,
      isLoading: true,
    });
    fetchQuestions(selectedLevel);
  };

  const [subCategory, setSubCategory] = useState('');

  // Add this new useEffect to fetch the subcategory
  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const response = await axios.get('/api/word-challenge/categories');
        const categoryData = response.data.find(cat => cat.id === category);
        if (categoryData) {
          setSubCategory(categoryData.name);
        }
      } catch (error) {
        console.error('Error fetching subcategory:', error);
      }
    };

    fetchSubCategory();
  }, [category]);

  if (gameState.isLoading) {
    return (
      <div className="container mx-auto p-4 text-center font-inter">
        <h2 className="text-2xl font-bold mb-4">Loading...</h2>
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
      </div>
    );
  }

  const currentWord = gameState.questions[gameState.currentQuestion] || {};
  const currentWordMedia = gameState.preloadedMedia[currentWord.id] || {};

  return (
    <GameContainer className="container mx-auto p-4 max-w-2xl">
      <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-900 font-poppins">
          {subCategory} - Level {gameState.currentLevel}
        </h2>

        <ProgressContainer>
          <ProgressBar>
            <ProgressFill style={{ width: `${currentLevelProgress}%` }} />
          </ProgressBar>
        </ProgressContainer>

        <div className="mb-6">
          {currentWordMedia.image_url ? (
            <div className="w-full h-64 mb-6 flex items-center justify-center bg-blue-100 rounded-lg">
              <img 
                src={currentWordMedia.image_url} 
                alt="Word" 
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ) : (
            <div className="w-full h-64 bg-gray-200 animate-pulse rounded-lg mb-6"></div>
          )}
          
          {currentWordMedia.audio_url && (
            <div className="flex justify-center mb-4">
              <button
                onClick={() => toggleAudio(true)}
                className="bg-blue-500 text-white p-3 rounded-full hover:bg-blue-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
              >
                <Volume2 className="w-6 h-6" />
              </button>
              <audio 
                ref={audioRef}
                src={currentWordMedia.audio_url} 
                onEnded={() => setIsPlaying(false)}
                className="hidden"
              />
            </div>
          )}
          
          <div className="relative">
            <AnimatedInput
              ref={inputRef}
              type="text"
              value={gameState.inputValue}
              onChange={handleInputChange}
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
              className={`w-full p-3 text-center border-4 focus:outline-none rounded-lg ${
                gameState.isCorrect === null ? 'border-gray-300' :
                gameState.isCorrect ? 'border-green-500' : 'border-red-500'
              }`}
              maxLength={currentWord.word ? currentWord.word.length : 20}
              disabled={gameState.showAnswer}
              placeholder=""
              onFocus={enableSound}
              inputMode="latin"  // Added this line to set input mode to English
            />
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none" style={{ fontFamily: 'Comic Neue, cursive', fontWeight: 700 }}>
              {(currentWord.word || '').toLowerCase().split('').map((char, index) => (
                <span
                  key={index}
                  className={`text-5xl ${index < gameState.inputValue.length ? 'invisible' : 'text-gray-400'}`}
                  style={{
                    textShadow: '2px 2px 0px #ffffff, 4px 4px 0px rgba(0, 0, 0, 0.1)',
                    letterSpacing: '0.1em',
                    textTransform: 'lowercase'
                  }}
                >
                  {char === ' ' ? ' ' : '_'}
                </span>
              ))}
            </div>
          </div>
        </div>

        {gameState.showAnswer && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="text-center mb-4"
          >
            <p className={`text-xl ${gameState.isCorrect ? 'text-green-500' : 'text-red-500'}`}>
              {gameState.isCorrect ? 'Correct!' : 'Incorrect.'}
            </p>
            <p className="text-lg text-gray-800 mt-2">
              <span className="font-bold text-2xl text-blue-600">{gameState.currentWordDetails.word}</span>
              {' '}
              <span className="text-gray-600">
                [{gameState.currentWordDetails.phonetic}] 
                ({gameState.currentWordDetails.partOfSpeech})
              </span>
            </p>
            <p className="text-lg text-gray-800 mt-1">
              {gameState.currentWordDetails.translation}
            </p>
          </motion.div>
        )}

        <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
          <div 
            className="bg-gradient-to-r from-blue-500 to-teal-500 h-2 rounded-full transition-all duration-500 ease-out" 
            style={{width: `${(gameState.timeLeft / 200) * 100}%`}}
          ></div>
        </div>

        <div className="flex justify-between items-center">
          <button
            onClick={handleHint}
            disabled={gameState.showHint || gameState.showAnswer}
            className="bg-yellow-500 text-white px-6 py-2 rounded-full hover:bg-yellow-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1 font-inter"
          >
            Hint
          </button>
          <button
            onClick={() => handleStartGame(gameState.currentLevel)}
            className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1 font-inter flex items-center"
          >
            <RefreshCw className="w-5 h-5 mr-2" /> Replay
          </button>
          <button
            onClick={() => navigate('/word-challenge')}
            className="bg-red-500 text-white px-6 py-2 rounded-full hover:bg-red-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1 font-inter"
          >
            Exit
          </button>
        </div>

        {showShortcutHint && (
          <p className="text-center text-gray-600 mt-4">
            Press <span className="bg-gray-200 font-bold px-1">Ctrl + E</span> to play/pause the audio
          </p>
        )} {/* Conditionally render the hint */}
      </div>

      <AnimatePresence>
        <motion.div
          key={gameState.combo}
          initial={{ opacity: 0, scale: 0.5, y: -50 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.5, y: -50 }}
          className="fixed top-24 right-4 bg-gradient-to-r from-blue-500 to-teal-500 text-white text-4xl font-bold p-4 rounded-full shadow-lg"
        >
          {gameState.combo}×
        </motion.div>
      </AnimatePresence>
    </GameContainer>
  );
};

export default GameLogic;