import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { Star, Lock } from 'lucide-react';
import { useUser } from '../UserContext';

const GameStartScreen = ({ onStartGame, category, subCategory }) => {
  const [categoryData, setCategoryData] = useState(null);
  const [userProgress, setUserProgress] = useState([]);
  const [totalLevels, setTotalLevels] = useState(3); // Default to 3 levels
  const { user } = useUser();
  const [categoryProgress, setCategoryProgress] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !category) return;

      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      try {
        const [categoriesResponse, progressResponse, wordsResponse] = await Promise.all([
          axios.get('/api/word-challenge/categories', config),
          axios.get(`/api/word-challenge/user-progress?user_id=${user.id}&category_id=${category}`, config),
          axios.get(`/api/word-challenge?category_id=${category}&level=1`, config)
        ]);

        const foundCategory = categoriesResponse.data.find(cat => cat.id === category);
        if (foundCategory) {
          setCategoryData(foundCategory);
        }

        setUserProgress(progressResponse.data);
        setTotalLevels(wordsResponse.data.total_levels);

        if (progressResponse.data.length > 0) {
          setCategoryProgress(progressResponse.data[0]);
        }

        // Preload images in the background
        const words = wordsResponse.data.words;
        words.forEach(word => {
          axios.get(`/api/word-challenge/media?word_id=${word.id}`, config)
            .then(mediaResponse => {
              const imageUrl = mediaResponse.data.image_url;
              new Image().src = imageUrl;
            });
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [category, user]);

  const renderStars = (level) => {
    const levelProgress = categoryProgress?.levels.find(l => l.level === level);
    const stars = levelProgress ? levelProgress.stars : 0;
    return (
      <div className="flex">
        {[1, 2, 3].map((star) => (
          <Star
            key={star}
            className={`w-6 h-6 ${star <= stars ? 'text-yellow-400 fill-current' : 'text-gray-300'}`}
          />
        ))}
      </div>
    );
  };

  const isLevelUnlocked = (level) => {
    if (level === 1) return true;
    const previousLevelProgress = categoryProgress?.levels.find(l => l.level === level - 1);
    return previousLevelProgress && previousLevelProgress.stars === 3;
  };

  const handleLevelClick = (level) => {
    if (isLevelUnlocked(level)) {
      onStartGame(level);
    } else {
      alert("You need to complete the previous level with 3 stars to unlock this level!");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 text-gray-900 p-6 font-inter">
      <div className="w-full max-w-md flex flex-col items-center">
        <motion.div 
          key={category}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-6"
        >
          <h1 className="text-4xl font-bold mb-1 font-poppins">
            {subCategory || categoryData?.name || 'Select a category'}
          </h1>
          <p className="text-xl font-semibold text-gray-700">
            {categoryData?.topic || 'Choose a category to start'}
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="w-full aspect-square bg-white rounded-lg overflow-hidden shadow-lg mb-6"
        >
          {categoryData ? (
            <img 
              src={categoryData.image}
              alt={categoryData.name} 
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-100">
              <p className="text-gray-500">Image not available</p>
            </div>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="w-full flex flex-wrap justify-center gap-4 mb-6"
        >
          {[...Array(totalLevels)].map((_, index) => {
            const level = index + 1;
            return (
              <motion.div 
                key={level} 
                className={`bg-white p-4 rounded-lg shadow text-center cursor-pointer ${
                  isLevelUnlocked(level) ? 'hover:bg-gray-100' : 'opacity-50'
                }`}
                style={{
                  width: 'calc(33.333% - 1rem)',
                  maxWidth: '150px',
                }}
                whileHover={isLevelUnlocked(level) ? { scale: 1.05 } : {}}
                onClick={() => handleLevelClick(level)}
              >
                <h2 className="text-lg font-semibold mb-2">Level {level}</h2>
                {renderStars(level)}
                {!isLevelUnlocked(level) && <Lock className="w-6 h-6 mx-auto mt-2 text-gray-400" />}
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default GameStartScreen;