import React, { useState } from 'react';
import styled from 'styled-components';
import { Volume2, Lightbulb } from 'lucide-react';

const WarmUpContainer = styled.div`
  font-family: 'Poppins', 'Inter', sans-serif;
`;

const WordItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const WordImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
`;

const WordInfo = styled.div`
  flex-grow: 1;
`;

const AdditionalInfo = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.25rem;
`;

const TipsButton = styled.button`
  background-color: #fbbf24;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  transition: all 0.3s;
  &:hover {
    background-color: #f59e0b;
  }
`;

const TipsContent = styled.div`
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 0.5rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const WarmUp = ({ words, preloadedMedia, onStartChallenge }) => {
  const [expandedTips, setExpandedTips] = useState(null);

  const playAudio = (audioUrl) => {
    const audio = new Audio(audioUrl);
    audio.play();
  };

  const toggleTips = (index) => {
    setExpandedTips(expandedTips === index ? null : index);
  };

  return (
    <WarmUpContainer className="container mx-auto p-4 max-w-2xl">
      <h2 className="text-3xl font-bold text-center mb-6 text-gray-900">Warm-up</h2>
      <div className="mb-6">
        {words.map((word, index) => (
          <React.Fragment key={index}>
            <WordItem>
              <WordImage src={preloadedMedia[word.id].image_url} alt={word.word} />
              <WordInfo>
                <p className="text-xl font-semibold">{word.word}</p>
                <p className="text-gray-600">{word.translation}</p>
                <AdditionalInfo>
                  {word.partOfSpeech && `${word.partOfSpeech} • `}
                  {word.phonetic && `${word.phonetic}`}
                </AdditionalInfo>
              </WordInfo>
              <IconContainer>
                {word.tips && word.tips.english && word.tips.chinese && (
                  <TipsButton onClick={() => toggleTips(index)}>
                    <Lightbulb size={20} />
                  </TipsButton>
                )}
                <button
                  onClick={() => playAudio(preloadedMedia[word.id].audio_url)}
                  className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-all duration-300 ml-2"
                >
                  <Volume2 size={20} />
                </button>
              </IconContainer>
            </WordItem>
            {expandedTips === index && (
              <TipsContent>
                <h3 className="text-lg font-semibold mb-2">Memory Tips</h3>
                <p className="mb-2">{word.tips.chinese}</p>
                <p>{word.tips.english}</p>
              </TipsContent>
            )}
          </React.Fragment>
        ))}
      </div>
      <button
        onClick={onStartChallenge}
        className="w-full bg-gradient-to-r from-blue-500 to-teal-500 text-white px-6 py-3 rounded-full hover:from-blue-600 hover:to-teal-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1 font-inter text-lg"
      >
        Start Challenge
      </button>
    </WarmUpContainer>
  );
};

export default WarmUp;