import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../fonts.css';
import axios from 'axios';


const ScenarioCard = ({ image_url, name }) => (
  <div className="group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105 cursor-pointer aspect-square relative">
    <div 
      className="absolute inset-0 bg-gray-200 bg-cover bg-center"
      style={{backgroundImage: `url(${encodeURI(image_url) || "https://via.placeholder.com/300x300"})`}}
    ></div>
    <div className="absolute inset-x-0 bottom-0 bg-white bg-opacity-80 p-4">
      <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 transition-colors duration-300 font-poppins truncate">{name}</h3>
    </div>
  </div>
);

const ScenarioSelect = () => {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchScenarios();
  }, []);

  const fetchScenarios = async () => {
    try {
      const response = await axios.get('/api/scenarios');
      setScenarios(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching scenarios:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading scenarios...</div>;
  }

  return (
    <div className="space-y-12 bg-gray-50 p-8 rounded-lg font-inter">
      <section>
        <h2 className="text-3xl font-bold mb-6 text-gray-900 font-poppins">Select a Scenario</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {scenarios.map((scenario) => (
            <Link key={scenario.id} to={`scenario/${scenario.id}`} className="no-underline">
              <ScenarioCard image_url={scenario.image_url} name={scenario.name} />
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ScenarioSelect;