import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

import AbilitySummary from './AbilitySummary';
import RecentScore from './RecentScore';
import StudyActivity from './StudyActivity';
import ModuleCard from './ModuleCard';
import { Book, Mic, Headphones, Languages } from 'lucide-react';

const RecordPage = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    fetchProgress();
  }, []);

  const fetchProgress = async () => {
    try {
      const response = await api.get('/api/progress');
      setProgress(response.data);
    } catch (error) {
      console.error('Error fetching progress:', error);
    }
  };
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Your Learning Record</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <AbilitySummary />
        <RecentScore />
      </div>

      <div className="mb-6">
        <StudyActivity selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ModuleCard
          title="Word Challenge"
          icon={<Book className="text-blue-500" size={24} />}
          metrics={[
            { label: "Points Earned", value: "2000" },
            { label: "Accuracy Rate", value: "90%" },
            { label: "Average Time per Question", value: "5 seconds" },
            { label: "Best Category", value: "Animals (95% accuracy)" }
          ]}
        />
        <ModuleCard
          title="Dictation"
          icon={<Mic className="text-green-500" size={24} />}
          metrics={[
            { label: "Words Transcribed", value: "5000" },
            { label: "Accuracy Rate", value: "85%" },
            { label: "Average Speed", value: "40 WPM" },
            { label: "Most Improved", value: "Punctuation" }
          ]}
        />
        <ModuleCard
          title="Shadowing"
          icon={<Headphones className="text-purple-500" size={24} />}
          metrics={[
            { label: "Practice Sessions", value: "50" },
            { label: "Pronunciation Score", value: "88/100" },
            { label: "Fluency Score", value: "82/100" },
            { label: "Most Practiced Accent", value: "British" }
          ]}
        />
        <ModuleCard
          title="Translation"
          icon={<Languages className="text-orange-500" size={24} />}
          metrics={[
            { label: "Sentences Translated", value: "1000" },
            { label: "Accuracy Rate", value: "78%" },
            { label: "Average Time per Sentence", value: "45 seconds" },
            { label: "Strongest Language Pair", value: "English-Spanish" }
          ]}
        />
      </div>
    </div>
  );
};

export default RecordPage;
