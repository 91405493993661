import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import LevelSummary from './LevelSummary';
import axios from 'axios';
import { useState } from 'react';

const EndGameContainer = styled(motion.div)`
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: #e6e6fa;
  border-radius: 20px;
  padding: 40px;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const LevelBanner = styled.div`
  background-color: #ffa500;
  color: white;
  font-size: 32px;
  font-weight: bold;
  padding: 15px 30px;
  border-radius: 30px;
  margin: -50px auto 30px;
  width: fit-content;
  box-shadow: 0 5px 15px rgba(255, 165, 0, 0.3);
`;

const ScoreText = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: #4b0082;
  margin: 30px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Star = styled(motion.div)`
  width: 80px;
  height: 80px;
  margin: 0 10px;
  background-color: ${props => props.filled ? '#ffd700' : '#d3d3d3'};
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  box-shadow: ${props => props.filled ? '0 0 20px #ffd700' : 'none'};
  transition: all 0.3s ease;
`;

const Button = styled(motion.button)`
  background-color: #32cd32;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  margin: 15px;
  box-shadow: 0 5px 15px rgba(50, 205, 50, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background-color: #28a428;
    transform: translateY(-3px);
    box-shadow: 0 7px 20px rgba(50, 205, 50, 0.4);
  }
`;

const RetryButton = styled(Button)`
  background-color: #1e90ff;
  box-shadow: 0 5px 15px rgba(30, 144, 255, 0.3);

  &:hover {
    background-color: #1873cc;
    box-shadow: 0 7px 20px rgba(30, 144, 255, 0.4);
  }
`;

const ExitButton = styled(Button)`
  background-color: #ff6347;
  box-shadow: 0 5px 15px rgba(255, 99, 71, 0.3);

  &:hover {
    background-color: #cc4f3a;
    box-shadow: 0 7px 20px rgba(255, 99, 71, 0.4);
  }
`;

const SummaryWrapper = styled.div`
  width: 90%;
  max-width: 600px;
  margin: 30px auto 0;
`;

const ModalContent = styled.div`
  text-align: center;
  padding: 20px;
`;

const ModalButton = styled(Button)`
  margin: 10px;
`;

const EndGameScreen = ({ level, score, stars, onNext, onRetry, onExit, maxLevel, gameState, categoryId }) => {
  const [addToNoteStatus, setAddToNoteStatus] = useState('');

  const isLastLevel = level >= maxLevel;
  const hasThreeStars = stars === 3;

  const handleAddToWordNote = async () => {
    try {
      const token = localStorage.getItem('token');

      const axiosInstance = axios.create({
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const challengeNoteResponse = await axiosInstance.post('/api/word-notes/challenge', { categoryId });
      const { id: noteId, pageId } = challengeNoteResponse.data;

      const errorWords = gameState.userAnswers
        .filter(answer => !answer.isCorrect)
        .map(answer => gameState.questions[answer.question]);

      console.log('Error words:', errorWords);

      const addWordPromises = errorWords.map(word =>
        axiosInstance.post(`/api/word-notes/${noteId}/pages/${pageId}/words/challenge`, { wordId: word.id })
      );

      await Promise.all(addWordPromises);

      setAddToNoteStatus('Words added to Word Note successfully!');
    } catch (error) {
      console.error('Error adding words to Word Note:', error);
      if (error.response && error.response.status === 401) {
        setAddToNoteStatus('Your session has expired. Please log in again.');
      } else {
        setAddToNoteStatus('Failed to add words to Word Note. Please try again.');
      }
    }
  };

  return (
    <>
      <EndGameContainer
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <LevelBanner>LEVEL {level}</LevelBanner>
        <StarContainer>
          {[1, 2, 3].map((_, index) => (
            <Star
              key={index}
              filled={index < stars}
              initial={{ rotate: -30, scale: 0 }}
              animate={{ rotate: 0, scale: 1 }}
              transition={{ delay: index * 0.2, type: "spring", stiffness: 260, damping: 20 }}
              whileHover={{ scale: 1.2, rotate: 5 }}
            />
          ))}
        </StarContainer>
        <ScoreText>SCORE<br/>{score}</ScoreText>
        <div>
          <RetryButton
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => onRetry(level)}
          >
            <span role="img" aria-label="retry">🔄</span> RETRY
          </RetryButton>
          {!isLastLevel && hasThreeStars && (
            <Button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => onNext(level + 1)}
            >
              NEXT <span role="img" aria-label="next">➡️</span>
            </Button>
          )}
          <ExitButton
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onExit}
          >
            EXIT <span role="img" aria-label="exit">🚪</span>
          </ExitButton>
        </div>
      </EndGameContainer>
      <SummaryWrapper>
        <LevelSummary 
          gameState={gameState} 
          categoryId={categoryId} 
          onAddToWordNote={handleAddToWordNote}
        />
        {addToNoteStatus && <p>{addToNoteStatus}</p>}
      </SummaryWrapper>
    </>
  );
};

export default EndGameScreen;