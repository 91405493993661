import React from 'react';
import { Box } from '@mui/material';

const AudioPlayer = ({ src }) => {
  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <audio controls style={{ width: '100%' }}>
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </Box>
  );
};

export default AudioPlayer;