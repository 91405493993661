import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Home, Book, Trophy, User, Settings, Search, Bell, MessageCircle, Gamepad, Menu, X, Bookmark } from 'lucide-react';

const NavItem = ({ icon: Icon, label, to, count, isSidebarOpen }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li className={`mb-4 relative ${isActive ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'} transition-colors duration-300`}>
      <Link to={to} className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-100 transition-all duration-300">
        <Icon className={`${isSidebarOpen ? 'mr-3' : 'mx-auto'}`} size={isSidebarOpen ? 20 : 24} />
        {isSidebarOpen && (
          <>
            <span className="flex-grow">{label}</span>
            {count && (
              <span className={`text-xs px-2 py-1 rounded-full ${
                isActive ? 'bg-blue-100 text-blue-600' : 'bg-gray-200 text-gray-600'
              }`}>
                {count}
              </span>
            )}
          </>
        )}
      </Link>
    </li>
  );
};

const Layout = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      fetchUserData(token);
    }
  }, []);

  useEffect(() => {
    // Check if the current route is a specific word note
    if (location.pathname.startsWith('/word-notes/') && location.pathname.split('/').length > 2) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [location.pathname]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <nav className={`${isSidebarOpen ? 'w-64' : 'w-20'} bg-white border-r border-gray-200 transition-all duration-300 ease-in-out flex flex-col`}>
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <div className="flex items-center">
            <img src="/logo192.png" alt="FifteenPenny Logo" className="w-8 h-8 mr-2" />
            {isSidebarOpen && <h1 className="text-xl font-bold text-gray-800">FifteenPenny</h1>}
          </div>
          <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-700">
            {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        <div className="flex-grow overflow-y-auto">
          <div className="p-4">
            <h2 className="text-xs font-semibold text-gray-400 mb-4">NAVIGATION</h2>
            <ul>
              <NavItem icon={Home} label="Home" to="/home" isSidebarOpen={isSidebarOpen} />
              <NavItem icon={Bookmark} label="Word Notes" to="/word-notes" isSidebarOpen={isSidebarOpen} />
              <NavItem icon={Gamepad} label="Word Challenge" to="/word-challenge" isSidebarOpen={isSidebarOpen} />
              <NavItem icon={Book} label="Lessons" to="/lesson" isSidebarOpen={isSidebarOpen} />
              <NavItem icon={Trophy} label="Leaderboard" to="/leaderboard" isSidebarOpen={isSidebarOpen} />
            </ul>
          </div>
          <div className="p-4">
            <h2 className="text-xs font-semibold text-gray-400 mb-4">INSIGHT</h2>
            <ul>
              <NavItem icon={User} label="Profile" to="/profile" isSidebarOpen={isSidebarOpen} />
            </ul>
          </div>
        </div>
        {isAuthenticated && user && (
          <div className="p-4 border-t border-gray-200">
            <Link to="/profile" className="flex items-center">
              <img 
                src={`${user.avatar}`} 
                alt="User avatar" 
                className="w-8 h-8 rounded-full mr-3 object-cover"
              />
              {isSidebarOpen && (
                <div className="flex-grow">
                  <p className="text-sm font-medium text-gray-700">{user.username}</p>
                  <p className="text-xs text-gray-500">{user.email}</p>
                </div>
              )}
            </Link>
          </div>
        )}
      </nav>
      
      <main className="flex-1 overflow-y-auto">
        <header className="bg-white border-b border-gray-200 p-4 sticky top-0 z-10">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800">Home</h2>
            <div className="flex items-center">
              {/* Search input commented out
              <div className="relative mr-4">
                <input
                  type="text"
                  placeholder="Search"
                  className="bg-gray-100 rounded-full py-2 px-4 pl-10 w-64 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
              */}
              {/* Notification bell commented out
              <button className="relative text-gray-600 hover:text-blue-600 transition-colors duration-300 mr-4">
                <Bell size={24} />
                <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
              </button>
              */}
              {isAuthenticated && user ? (
                <div className="flex items-center">
                  <img 
                    src={`${user.avatar}`} 
                    alt="User avatar" 
                    className="w-8 h-8 rounded-full mr-2 object-cover"
                  />
                  <span className="mr-2 text-gray-700">{user.username}</span>
                  <button onClick={handleLogout} className="bg-gradient-to-r from-blue-500 to-teal-500 text-white px-4 py-2 rounded-full hover:from-blue-600 hover:to-teal-600 transition-all duration-300 shadow-md hover:shadow-lg">
                    Logout
                  </button>
                </div>
              ) : (
                <Link to="/signin" className="bg-gradient-to-r from-blue-500 to-teal-500 text-white px-4 py-2 rounded-full hover:from-blue-600 hover:to-teal-600 transition-all duration-300 shadow-md hover:shadow-lg">
                  Login
                </Link>
              )}
            </div>
          </div>
        </header>
        <div className="p-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Layout;
