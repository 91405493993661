import React from 'react';
import { 
  Typography, Box, IconButton, TableContainer, Table, TableBody, TableCell, TableRow, Paper
} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { styled } from '@mui/material/styles';

const WordContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(0.5),
}));

const WordRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const VocabModule = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <Typography variant="h6">No vocabulary data available</Typography>
      </Box>
    );
  }

  const playAudio = (audioUrl) => {
    if (audioUrl) {
      new Audio(audioUrl).play();
    } else {
      console.error('No audio URL available for this word');
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', my: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
        Vocabulary & Phrases
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 600, overflow: 'auto' }}>
        <Table stickyHeader>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <WordContainer>
                    <WordRow>
                      <Typography variant="h6" component="span">
                        {item.word}
                      </Typography>
                      <IconButton onClick={() => playAudio(item.audio_url)} size="small">
                        <VolumeUpIcon />
                      </IconButton>
                    </WordRow>
                    <Typography variant="body2" color="text.secondary">
                      {item.phonetic}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.partOfSpeech}
                    </Typography>
                  </WordContainer>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">
                    {item.translation}
                  </Typography>
                  {item.exampleSentence && (
                    <Typography variant="body2" sx={{ fontStyle: 'italic', mt: 1 }}>
                      "{item.exampleSentence}"
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VocabModule;