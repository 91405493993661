import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Search, ChevronDown, ChevronUp } from 'lucide-react';
import '../fonts.css';
import { useUser } from '../UserContext';

const CategoryCard = ({ image, name, progress }) => {
  const encodedImageUrl = image.replace(/^(https?:\/\/[^\/]+)\/(.*)$/, (match, domain, path) => {
    return domain + '/' + encodeURIComponent(path);
  });
  
  return (
    <div className="group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105 cursor-pointer aspect-square flex flex-col">
      <div 
        className="flex-grow bg-gray-200 bg-cover bg-center"
        style={{backgroundImage: `url("${encodedImageUrl}")`}}
      />
      <div className="p-4 bg-white">
        <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 transition-colors duration-300 font-poppins mb-2 truncate">{name}</h3>
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-medium text-gray-600 font-inter">Progress</span>
          <span className="text-sm font-medium text-blue-600 font-inter">{progress}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div 
            className="bg-blue-600 h-2 rounded-full transition-all duration-500 ease-out" 
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

const TopicSection = ({ topic, categories, userProgress, isExpanded, onToggle }) => (
  <div className="mb-8">
    <div 
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer p-4 flex justify-between items-center"
      onClick={onToggle}
    >
      <h2 className="text-2xl font-bold text-gray-800 font-poppins">{topic}</h2>
      {isExpanded ? <ChevronUp className="text-gray-600" size={24} /> : <ChevronDown className="text-gray-600" size={24} />}
    </div>
    {isExpanded && (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-4">
        {categories.map((category) => (
          <Link key={category.id} to={`/word-challenge/${category.id}/game`}>
            <CategoryCard 
              image={category.image} 
              name={category.name} 
              progress={calculateCategoryProgress(userProgress, category.id)}
            />
          </Link>
        ))}
      </div>
    )}
  </div>
);

const calculateCategoryProgress = (userProgress, categoryId) => {
  const categoryProgress = userProgress.find(progress => progress.category_id === categoryId);
  if (!categoryProgress) return 0;

  const totalLevels = categoryProgress.total_levels || 3; // Use total_levels from progress, fallback to 3
  const completedLevels = categoryProgress.completed_levels || 0;

  // Calculate progress as a percentage
  const progress = (completedLevels / totalLevels) * 100;
  return Math.round(progress);
};

const CategorySelect = () => {
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userProgress, setUserProgress] = useState([]);
  const [expandedTopics, setExpandedTopics] = useState({});
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
    if (user) {
      fetchUserProgress();
    }
  }, [user]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/word-challenge/categories', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const groupedCategories = groupCategoriesByTopic(response.data);
      setCategories(groupedCategories);
      setExpandedTopics(Object.keys(groupedCategories).reduce((acc, topic) => {
        acc[topic] = true;
        return acc;
      }, {}));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories. Please try again later.');
      setLoading(false);
    }
  };

  const fetchUserProgress = async () => {
    if (!user) {
      console.log('User not logged in, skipping progress fetch');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/word-challenge/user-progress?user_id=${user.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserProgress(response.data);
    } catch (error) {
      console.error('Error fetching user progress:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const groupCategoriesByTopic = (categoriesArray) => {
    return categoriesArray.reduce((acc, category) => {
      if (!acc[category.topic]) {
        acc[category.topic] = [];
      }
      acc[category.topic].push(category);
      return acc;
    }, {});
  };

  const toggleTopic = (topic) => {
    setExpandedTopics(prev => ({ ...prev, [topic]: !prev[topic] }));
  };

  const filteredCategories = Object.entries(categories).reduce((acc, [topic, subcategories]) => {
    const filteredSubcategories = subcategories.filter(category => 
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredSubcategories.length > 0) {
      acc[topic] = filteredSubcategories;
    }
    return acc;
  }, {});

  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-12 bg-gray-50 p-8 rounded-lg font-inter">
      <section>
        <h2 className="text-3xl font-bold mb-6 text-gray-900 font-poppins">Word Challenge Categories</h2>
        <div className="mb-6 relative">
          <input
            type="text"
            placeholder="Search categories..."
            className="w-full p-4 pl-12 text-lg border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-4 top-4 text-gray-400" size={24} />
        </div>
        {Object.entries(filteredCategories).map(([topic, subcategories]) => (
          <TopicSection
            key={topic}
            topic={topic}
            categories={subcategories}
            userProgress={userProgress}
            isExpanded={expandedTopics[topic]}
            onToggle={() => toggleTopic(topic)}
          />
        ))}
      </section>
    </div>
  );
};

export default CategorySelect;