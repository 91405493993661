import React from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import ScenarioSelect from './ScenarioSelect';
import ScenarioPage from './ScenarioPage';

function LessonModule() {
  return (
    <Routes>
      <Route index element={<ScenarioSelect />} />
      <Route path="scenario/:scenarioId" element={<ScenarioPage />} />
    </Routes>
  );
}

export default LessonModule;