import React from 'react';

const ModuleCard = ({ title, icon, metrics }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        {icon}
        <h2 className="text-xl font-semibold ml-2">{title}</h2>
      </div>
      <ul className="space-y-2">
        {metrics.map((metric, index) => (
          <li key={index} className="flex justify-between items-center">
            <span className="text-gray-600">{metric.label}:</span>
            <span className="font-semibold">{metric.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModuleCard;
