import React, { useState, useCallback } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Loader, Sparkles, Trash2, Plus, CheckSquare, Square } from 'lucide-react';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  background: #f0f4f8;
  background-image: linear-gradient(
    to right,
    #f0f4f8 0%,
    #d9e2ec 20%,
    #f0f4f8 40%,
    #f0f4f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 4px;
`;

const GeneratingWords = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;

const WordItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }

  &:hover .delete-icon {
    opacity: 1;
  }
`;

const WordInfo = styled.div`
  flex-grow: 1;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 3.5em;
  overflow: hidden;

  ${WordItem}:hover & {
    max-height: 1000px;
    overflow: visible;
  }
`;

const WordText = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${WordItem}:hover & {
    white-space: normal;
    overflow: visible;
  }
`;

const TranslationText = styled.p`
  font-size: 0.75rem;
  color: #4B5563;
  margin-bottom: 0.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${WordItem}:hover & {
    white-space: normal;
    overflow: visible;
  }
`;

const PhoneticText = styled.p`
  font-size: 0.625rem;
  color: #6B7280;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

const ScrollableWordList = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;

  /* Customizing scrollbar for webkit browsers */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const OutputPanel = styled.div`
  background-color: #f0f4f8;
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const OutputTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 1rem;
`;

const SelectButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  background-color: transparent;
  color: #3b82f6;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #2563eb;
  }
`;

const GenerateWordsPanel = ({ onAddWords, showError }) => {
  const [description, setDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedWords, setGeneratedWords] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);
  const [topic, setTopic] = useState(''); // Add this line to store the topic

  const handleGenerateWords = useCallback(async () => {
    if (!description.trim()) return;

    setIsGenerating(true);
    setGeneratedWords([]);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/api/generate-words', 
        { description },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const data = response.data;

      if (data.words && data.words.length > 0) {
        setGeneratedWords(data.words || []);
        setTopic(data.topic); // Store the topic from the response
        showError(`Words generated successfully. You have used ${data.maxDailyGenerations - data.remainingGenerations} out of ${data.maxDailyGenerations} generations today.`, 'info');
      } else {
        showError(data.error || 'No words were generated. Please try a different description.', 'warning');
      }
    } catch (error) {
      console.error('Error generating words:', error);
      if (error.response && error.response.status === 429) {
        showError(`You have reached the daily limit of ${error.response.data.maxDailyGenerations} word generations.`);
      } else {
        showError(error.response?.data?.error || 'Failed to generate words. Please try again.');
      }
    } finally {
      setIsGenerating(false);
    }
  }, [description, showError]);

  const handleGenerateMoreWords = useCallback(async () => {
    if (!description.trim() || generatedWords.length === 0) return;

    setIsGenerating(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/api/generate-more-words', 
        { 
          description,
          context_words: generatedWords
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const data = response.data;

      if (data.words && data.words.length > 0) {
        setGeneratedWords(prevWords => [...prevWords, ...data.words]);
        showError(`${data.words.length} more words generated successfully.`, 'info');
      } else {
        showError(data.error || 'No additional words were generated. Please try a different description.', 'warning');
      }
    } catch (error) {
      console.error('Error generating more words:', error);
      showError(error.response?.data?.error || 'Failed to generate more words. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  }, [description, generatedWords, showError]);

  const handleDeleteGeneratedWord = useCallback((indexToDelete) => {
    setGeneratedWords(prevWords => prevWords.filter((_, index) => index !== indexToDelete));
  }, []);

  const handleWordSelection = useCallback((index) => {
    setSelectedWords(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  }, []);

  const handleDeleteSelectedWords = useCallback(() => {
    setGeneratedWords(prevWords => prevWords.filter((_, index) => !selectedWords.includes(index)));
    setSelectedWords([]);
  }, [selectedWords]);

  const handleAddToPage = () => {
    onAddWords(generatedWords, topic); // Pass the topic along with the words
    setGeneratedWords([]);
    setDescription('');
    setTopic(''); // Reset the topic after adding words
  };

  return (
    <>
      <div className="bg-white rounded-xl p-6 shadow-md transition-all duration-300 hover:shadow-lg">
        <h2 className="text-xl font-semibold mb-4 text-gray-800 font-poppins">Generate Words</h2>
        <div className="relative">
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value.slice(0, 300))}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isGenerating && description.trim().length > 0) {
                handleGenerateWords();
              }
            }}
            placeholder="Enter a topic (e.g. 运动项目,厨房相关...)"
            className="w-full p-4 pr-12 bg-gray-100 rounded-lg text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-300 font-inter"
            maxLength={300}
          />
          <button
            onClick={handleGenerateWords}
            disabled={isGenerating || description.trim().length === 0}
            className="absolute right-2 top-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            {isGenerating ? (
              <Loader className="animate-spin" size={24} />
            ) : (
              <Sparkles size={24} />
            )}
          </button>
        </div>
        {description.length === 300 && (
          <div className="text-sm text-gray-600 mt-2">
            Maximum character limit reached
          </div>
        )}
        {isGenerating && (
          <GeneratingWords>
            <p className="text-sm text-blue-600 mb-2 flex items-center">
              <Loader className="animate-spin mr-2" size={16} />
              Generating words...
            </p>
            <ShimmerEffect />
            <ShimmerEffect />
            <ShimmerEffect />
          </GeneratingWords>
        )}
      </div>

      {Array.isArray(generatedWords) && generatedWords.length > 0 && (
        <OutputPanel>
          <ScrollableWordList>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
              {generatedWords.map((word, index) => (
                <WordItem key={index}>
                  <SelectButton
                    onClick={() => handleWordSelection(index)}
                    aria-label={selectedWords.includes(index) ? "Deselect word" : "Select word"}
                  >
                    {selectedWords.includes(index) ? <CheckSquare size={14} /> : <Square size={14} />}
                  </SelectButton>
                  <WordInfo>
                    <WordText>{word.word}</WordText>
                    <TranslationText>
                      {word.translation.map((trans, i) => (
                        <span key={i}>{i > 0 && '; '}{trans}</span>
                      ))}
                    </TranslationText>
                    {word.phonetic && <PhoneticText>/{word.phonetic}/</PhoneticText>}
                  </WordInfo>
                </WordItem>
              ))}
            </div>
          </ScrollableWordList>
          <div className="flex flex-col mt-4">
            {selectedWords.length > 0 && (
              <button
                onClick={handleDeleteSelectedWords}
                className="bg-red-500 text-white px-6 py-2 rounded-full hover:bg-red-600 transition-colors duration-300 font-inter flex items-center justify-center mb-2"
              >
                <Trash2 size={20} className="mr-2" />
                Delete Selected ({selectedWords.length})
              </button>
            )}
            <div className="flex justify-between">
              <button
                onClick={handleAddToPage}
                className="bg-green-600 text-white px-6 py-2 rounded-full hover:bg-green-700 transition-colors duration-300 font-inter flex items-center justify-center"
              >
                <Plus size={20} className="mr-2" />
                Add to Page
              </button>
              {generatedWords.length < 20 && (
                <button
                  onClick={handleGenerateMoreWords}
                  disabled={isGenerating}
                  className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300 font-inter flex items-center justify-center"
                >
                  {isGenerating ? (
                    <Loader className="animate-spin mr-2" size={20} />
                  ) : (
                    <Sparkles size={20} className="mr-2" />
                  )}
                  More Words
                </button>
              )}
            </div>
          </div>
        </OutputPanel>
      )}
    </>
  );
};

export default GenerateWordsPanel;