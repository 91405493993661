import React from 'react';

const SettingsPage = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>
      <p>Settings content coming soon...</p>
    </div>
  );
};

export default SettingsPage;