import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../../fonts.css';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Volume2, Lightbulb, Trash2, X, Plus, Wand2, Loader, Search, Sparkles } from 'lucide-react';
import WordTipsPanel from './WordTipsPanel';
import PageItem from './PageItem';
import DictationExercise from './DictationExercise';
import ReactMarkdown from 'react-markdown';
import GenerateWordsPanel from './GenerateWordsPanel';
import { debounce } from 'lodash';

const WordItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 0.5rem; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const WordImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
`;

const WordInfo = styled.div`
  flex-grow: 1;
`;

const AdditionalInfo = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.25rem;
`;

const TipsButton = styled.button`
  background-color: #fbbf24;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  transition: all 0.3s;
  &:hover {
    background-color: #f59e0b;
  }
`;

const TipsContent = styled.div`
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 1rem;
  marg
  in-top: 0.5rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  background: #f0f4f8;
  background-image: linear-gradient(
    to right,
    #f0f4f8 0%,
    #d9e2ec 20%,
    #f0f4f8 40%,
    #f0f4f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 4px;
`;

const GeneratingWords = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;

const WordsPanel = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 1rem;
  
  /* For webkit browsers like Chrome and Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const WordItemComponent = ({ word, onDelete, onSelect }) => {
  const [showTips, setShowTips] = useState(false);
  const [audioUrl, setAudioUrl] = useState(word.audioUrl);

  const playAudio = useCallback(async (e) => {
    e.stopPropagation();
    onSelect(word); // Select the word when audio button is clicked
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.play().catch(error => {
        console.error("Error playing audio:", error);
        fetchAudio();
      });
    } else {
      fetchAudio();
    }
  }, [audioUrl, onSelect, word]);

  const fetchAudio = async () => {
    try {
      const response = await axios.post('/api/generate-audio', { word: word.word }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setAudioUrl(response.data.audioUrl);
      // Play the audio immediately after fetching
      const audio = new Audio(response.data.audioUrl);
      audio.play();
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  const toggleTips = useCallback((e) => {
    e.stopPropagation();
    onSelect(word); // Select the word when tips button is clicked
    setShowTips(prev => !prev);
  }, [onSelect, word]);

  return (
    <>
      <WordItem onClick={() => onSelect(word)}>
        {word.imageUrl && <WordImage src={word.imageUrl} alt={word.word} />}
        <WordInfo>
          <p className="text-xl font-semibold">{word.word}</p>
          {Array.isArray(word.translation) ? (
            <p className="text-gray-600">
              {word.translation[0]}
              {word.translation.length > 1 && " (...)"}
            </p>
          ) : (
            <p className="text-gray-600">{word.translation}</p>
          )}
          <AdditionalInfo>
            {word.partOfSpeech && `${word.partOfSpeech} • `}
            {word.phonetic && `${word.phonetic}`}
          </AdditionalInfo>
        </WordInfo>
        <IconContainer>
          {word.tips && Object.keys(word.tips).length > 0 && (
            <TipsButton onClick={toggleTips}>
              <Lightbulb size={20} />
            </TipsButton>
          )}
          {audioUrl && (
            <button
              onClick={playAudio}
              className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-all duration-300 ml-2"
            >
              <Volume2 size={20} />
            </button>
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(word.id);
            }}
            className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-all duration-300 ml-2"
          >
            <Trash2 size={20} />
          </button>
        </IconContainer>
      </WordItem>
    </>
  );
};

const WordNoteContent = () => {
  const { noteId } = useParams();
  const [note, setNote] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedWord, setSelectedWord] = useState(null);
  const [isAddingWord, setIsAddingWord] = useState(false);
  const [newWord, setNewWord] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const errorTimeoutRef = useRef(null);
  const [isAddingWordLoading, setIsAddingWordLoading] = useState(false);
  const [isDictationMode, setIsDictationMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [description, setDescription] = useState('');
  const [generatedWords, setGeneratedWords] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [pagesWithWords, setPagesWithWords] = useState({});
  const location = useLocation();
  const [recommendedWords, setRecommendedWords] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

  // Add this constant for the maximum number of pages
  const MAX_PAGES = 20;

  // Define showError function first
  const showError = useCallback((message, type = 'error') => {
    setErrorMessage({ message, type });
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    errorTimeoutRef.current = setTimeout(() => {
      setErrorMessage('');
    }, 5000); // Hide message after 5 seconds
  }, []);

  const fetchPageWords = useCallback(async (pageId) => {
    console.log(`Fetching words for page ${pageId}`);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/word-notes/${noteId}/pages/${pageId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(`Received words for page ${pageId}:`, response.data);
      setPagesWithWords(prev => ({
        ...prev,
        [pageId]: response.data.words || []
      }));
      setSelectedPage(response.data);
    } catch (error) {
      console.error(`Error fetching words for page ${pageId}:`, error);
      showError(`Failed to fetch words for page ${pageId}. Please try again.`);
    }
  }, [noteId, showError]);

  const fetchNote = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/word-notes/${noteId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setNote(response.data);
      setPages(response.data.pages);
      
      // Initialize pagesWithWords with empty arrays for each page
      const initialPagesWithWords = {};
      response.data.pages.forEach(page => {
        initialPagesWithWords[page.id] = [];
      });
      setPagesWithWords(initialPagesWithWords);
      
      // Check if we should focus on the first page
      const searchParams = new URLSearchParams(location.search);
      const shouldFocusFirstPage = searchParams.get('focusFirstPage') === 'true';
      
      if (shouldFocusFirstPage && response.data.pages.length > 0) {
        setSelectedPageId(response.data.pages[0].id);
      } else if (response.data.pages.length > 0) {
        // Default behavior: select the first page
        setSelectedPageId(response.data.pages[0].id);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching note:', error);
      setLoading(false);
    }
  }, [noteId, location.search]);

  useEffect(() => {
    fetchNote();
  }, [fetchNote]);

  useEffect(() => {
    if (selectedPageId) {
      fetchPageWords(selectedPageId);
    }
  }, [selectedPageId, fetchPageWords]);

  const handleAddNewPage = useCallback(async () => {
    console.log('Adding new page');
    if (pages.length >= 20) {
      showError('You have reached the maximum limit of 20 pages per note.');
      return;  
    }
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/word-notes/${noteId}/pages`, {
        title: 'New Page',
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const newPage = response.data;
      console.log('New page created:', newPage);
      
      setPages(prev => [...prev, newPage]);
      setPagesWithWords(prev => ({
        ...prev,
        [newPage.id]: []
      }));
      
      setSelectedPage({
        ...newPage,
        words: []
      });
      setSelectedPageId(newPage.id);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error adding new page:', error);
      showError('Failed to add new page. Please try again.');
    }
  }, [noteId, showError, pages.length]);

  const handlePageSelect = useCallback((page) => {
    console.log(`Selecting page:`, page);
    setSelectedPageId(page.id);
    setCurrentPage(1);
    if (!pagesWithWords[page.id] || pagesWithWords[page.id].length === 0) {
      fetchPageWords(page.id);
    } else {
      console.log(`Using cached words for page ${page.id}`);
      setSelectedPage({
        ...page,
        words: pagesWithWords[page.id]
      });
    }
  }, [fetchPageWords, pagesWithWords]);

  const handleDeletePage = useCallback(async (pageId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/word-notes/${noteId}/pages/${pageId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPages(prev => prev.filter(page => page.id !== pageId));
      if (selectedPage?.id === pageId) {
        setSelectedPage(pages[0] || null);
      }
    } catch (error) {
      console.error('Error deleting page:', error);
    }
  }, [noteId, selectedPage, pages]);

  const handleDeleteWord = useCallback(async (wordId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/word-notes/${noteId}/pages/${selectedPage.id}/words/${wordId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSelectedPage(prev => ({
        ...prev,
        words: prev.words.filter(word => word.id !== wordId)
      }));
      setPagesWithWords(prev => ({
        ...prev,
        [selectedPage.id]: prev[selectedPage.id].filter(word => word.id !== wordId)
      }));
      
      // Clear the selected word if the deleted word was selected
      setSelectedWord(prevSelected => 
        prevSelected && prevSelected.id === wordId ? null : prevSelected
      );
    } catch (error) {
      console.error('Error deleting word:', error);
    }
  }, [noteId, selectedPage]);

  const handleWordSelect = useCallback((word) => {
    setSelectedWord(word);
  }, []);

  useEffect(() => {
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, []);

  const fetchRecommendedWords = useCallback(
    debounce(async (input) => {
      if (input.length < 1) {
        setRecommendedWords([]);
        return;
      }

      try {
        const response = await axios.get(`/api/word-recommendations?prefix=${input}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        setRecommendedWords(response.data.recommendations);
        setSelectedIndex(-1); // Reset selected index when new recommendations arrive
      } catch (error) {
        console.error('Error fetching word recommendations:', error);
      }
    }, 300),
    []
  );

  const handleNewWordChange = (e) => {
    const input = e.target.value;
    setNewWord(input);
    fetchRecommendedWords(input);
  };

  const handleRecommendedWordClick = (word) => {
    setNewWord(word.word);
    setRecommendedWords([]);
  };

  const handleKeyDown = (e) => {
    if (recommendedWords.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prevIndex => 
          prevIndex < recommendedWords.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : -1));
        break;
      case 'Enter':
        if (selectedIndex >= 0) {
          e.preventDefault();
          handleRecommendedWordClick(recommendedWords[selectedIndex]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < recommendedWords.length) {
      setNewWord(recommendedWords[selectedIndex].word);
    }
  }, [selectedIndex, recommendedWords]);

  const handleAddNewWord = useCallback(async () => {
    if (!newWord.trim()) return;

    if (selectedPage.words.length >= 30) {
      showError('You have reached the maximum limit of 30 words per page.');
      return;
    }

    setIsAddingWordLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/word-notes/${noteId}/pages/${selectedPage.id}/words`, {
        word: newWord.trim(),
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setSelectedPage(prev => ({
        ...prev,
        words: Array.isArray(prev.words) ? [response.data, ...prev.words].slice(0, 30) : [response.data]
      }));
      
      setPagesWithWords(prev => ({
        ...prev,
        [selectedPage.id]: [response.data, ...prev[selectedPage.id]].slice(0, 30)
      }));
      
      setSelectedWord(response.data);
      setNewWord('');
      setIsAddingWord(false);
    } catch (error) {
      console.error('Error adding new word:', error);
      if (error.response && error.response.data) {
        showError(
          error.response.data.error || 'An error occurred while adding the word.',
          error.response.data.resolution || 'Please try again.'
        );
      } else {
        showError('An error occurred while adding the word. Please try again.');
      }
    } finally {
      setIsAddingWordLoading(false);
    }
  }, [newWord, noteId, selectedPage, showError]);

  const handleNewWordKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission if within a form
      if (selectedIndex >= 0 && selectedIndex < recommendedWords.length) {
        // If a recommendation is selected, use that word
        handleRecommendedWordClick(recommendedWords[selectedIndex]);
      } else {
        // Otherwise, add the current input as a new word
        handleAddNewWord();
      }
    }
  }, [handleAddNewWord, recommendedWords, selectedIndex, handleRecommendedWordClick]);

  const handleEditPage = useCallback(async (pageId, newTitle) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`/api/word-notes/${noteId}/pages/${pageId}`, {
        title: newTitle
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPages(prev => prev.map(page => 
        page.id === pageId ? { ...page, title: response.data.title } : page
      ));
      if (selectedPage?.id === pageId) {
        setSelectedPage(prev => ({ ...prev, title: response.data.title }));
      }
    } catch (error) {
      console.error('Error updating page title:', error);
    }
  }, [noteId, selectedPage]);

  const handleStartDictation = useCallback(async () => {
    if (selectedPage && selectedPage.words && selectedPage.words.length > 0) {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/word-notes/${noteId}/pages/${selectedPage.id}/start-dictation`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data.words && response.data.words.length > 0) {
          setIsDictationMode(true);
          setSelectedPage(prev => ({
            ...prev,
            words: response.data.words
          }));
        } else {
          showError('No words available for dictation. Please add some words to this page first.');
        }
      } catch (error) {
        console.error('Error starting dictation:', error);
        showError('Failed to start dictation. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      showError('No words available for dictation. Please add some words to this page first.');
    }
  }, [noteId, selectedPage, showError]);

  const handleEndDictation = useCallback(() => {
    setIsDictationMode(false);
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedWords = selectedPage?.words?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage  
  );

  const totalPages = Math.ceil((selectedPage?.words?.length || 0) / itemsPerPage);

  const handleBatchAddWords = useCallback(async (wordsToAdd, topic) => {
    if (wordsToAdd.length === 0) return;

    const availableSlots = 30 - (selectedPage.words?.length || 0);
    if (availableSlots <= 0) {
      showError('You have reached the maximum limit of 30 words per page.');
      return;
    }

    const wordsToAddLimited = wordsToAdd.slice(0, availableSlots);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/word-notes/${noteId}/pages/${selectedPage.id}/words/batch-add`, {
        language: 'en',
        words: wordsToAddLimited,
        topic: topic // Include the topic in the request
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.words && response.data.words.length > 0) {
        setSelectedPage(prev => ({
          ...prev,
          title: response.data.pageTitle || prev.title, // Update page title if provided
          words: Array.isArray(prev.words) 
            ? [...response.data.words, ...prev.words].slice(0, 30)
            : response.data.words
        }));
        setPagesWithWords(prev => ({
          ...prev,
          [selectedPage.id]: [...response.data.words, ...prev[selectedPage.id]].slice(0, 30)
        }));
        
        // Update the pages state to reflect the new page title
        setPages(prevPages => prevPages.map(page => 
          page.id === selectedPage.id 
            ? { ...page, title: response.data.pageTitle || page.title }
            : page
        ));

        showError(`${response.data.words.length} word(s) added successfully!`, 'success');
      } else {
        showError('No new words were added. They might already exist on the page.');
      }
    } catch (error) {
      console.error('Error batch adding words:', error);
      showError('Failed to add words. Please try again.');
    }
  }, [noteId, selectedPage, showError, setPages]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen py-4 px-2 sm:px-4">
      <div className="max-w-[95%] mx-auto">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-6">
          {/* Left sidebar */}
          <div className="lg:w-[20%] space-y-3 lg:sticky lg:top-4 lg:self-start lg:max-h-[calc(100vh-2rem)] lg:overflow-y-auto">
            {note && (
              <div className="bg-white rounded-xl overflow-hidden shadow-md mb-4">
                <div className="p-4">
                  <button 
                    onClick={() => navigate('/word-notes')} 
                    className="text-blue-600 mb-2 hover:underline font-inter"
                  >
                    ← Back to Notes
                  </button>
                  <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-2 font-poppins">{note.title}</h1>
                </div>
              </div>
            )}
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 font-poppins">Pages</h2>
              <span className="text-sm text-gray-600 font-inter">
                {pages.length}/{MAX_PAGES}
              </span>
            </div>
            {pages.map((page, index) => (
              <PageItem
                key={page.id}
                index={index + 1}
                title={page.title}
                isSelected={selectedPage?.id === page.id}
                onClick={() => handlePageSelect(page)}
                onDelete={() => handleDeletePage(page.id)}
                onEdit={(newTitle) => handleEditPage(page.id, newTitle)}
              />
            ))}
            <div className="flex items-center justify-between mt-4">
              <button 
                onClick={handleAddNewPage}
                className="flex-grow bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors duration-300 font-inter"
                disabled={pages.length >= MAX_PAGES}
              >
                Add New Page
              </button>
            </div>
          </div>

          {/* Middle content */}
          <div className="lg:w-[50%] space-y-4">
            <GenerateWordsPanel 
              onAddWords={handleBatchAddWords} 
              showError={showError} 
            />

            {isDictationMode ? (
              <DictationExercise 
                words={selectedPage?.words || []}
                noteId={noteId}
                pageId={selectedPage?.id || ''}
                onClose={handleEndDictation}
                theme={note.themeColor}
              />
            ) : selectedPage ? (
              <div className="bg-white rounded-xl p-6 shadow-md">
                <h2 className="text-2xl font-semibold mb-4 text-gray-800 font-poppins">{selectedPage.title}</h2>
                {/* New word input and add button */}
                <div className="mb-4 flex flex-col items-start">
                  <div className="w-full flex items-center">
                    <input
                      ref={inputRef}
                      type="text"
                      value={newWord}
                      onChange={handleNewWordChange}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                        handleNewWordKeyPress(e);
                      }}
                      placeholder="Enter a new word"
                      className="flex-grow p-2 border rounded-l-md font-inter"
                      disabled={isAddingWordLoading}
                    />
                    <button 
                      onClick={handleAddNewWord}
                      className="bg-blue-600 text-white px-4 py-2 rounded-r-md hover:bg-blue-700 transition-colors duration-300 font-inter flex items-center"
                      disabled={isAddingWordLoading}
                    >
                      {isAddingWordLoading ? (
                        <Loader className="animate-spin mr-2" size={20} />
                      ) : (
                        'Add'
                      )}
                    </button>
                  </div>
                  {recommendedWords.length > 0 && (
                    <div className="mt-2 bg-white border border-gray-300 rounded-md shadow-sm w-full">
                      {recommendedWords.map((word, index) => (
                        <div
                          key={index}
                          className={`p-2 hover:bg-gray-100 cursor-pointer flex justify-between ${
                            index === selectedIndex ? 'bg-blue-100' : ''
                          }`}
                          onClick={() => handleRecommendedWordClick(word)}
                        >
                          <span>{word.word}</span>
                          <span className="text-gray-500">{word.translation}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <button 
                  onClick={handleStartDictation}
                  className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition-colors duration-300 font-inter flex items-center mb-4"
                >
                  Start Dictation
                </button>
                {isAddingWordLoading && (
                  <div className="mb-4 text-sm text-gray-600 font-inter">
                    Adding word... This may take a moment.
                  </div>
                )}
                {errorMessage && (
                  <div className={`mb-4 p-3 rounded ${
                    errorMessage.type === 'error' 
                      ? 'bg-red-100 border border-red-400 text-red-700' 
                      : 'bg-blue-100 border border-blue-400 text-blue-700'
                  }`}>
                    <p>{errorMessage.message}</p>
                  </div>
                )}
                <WordsPanel>
                  {paginatedWords && paginatedWords.length > 0 ? (
                    paginatedWords
                      .filter(word => word !== null)
                      .map((word) => (
                        <WordItemComponent 
                          key={word.id} 
                          word={word} 
                          onDelete={handleDeleteWord}
                          onSelect={handleWordSelect}
                        />
                      ))
                  ) : (
                    <p className="text-gray-600 font-inter">No words added to this page yet.</p>
                  )}
                </WordsPanel>
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 rounded-full ${
                          currentPage === index + 1 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
                        }`}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div className="bg-white rounded-xl p-4 sm:p-6 shadow-md">
                <p className="text-base sm:text-lg text-center text-gray-600 font-inter">
                  Select a page to view its content or create a new page.
                </p>
              </div>
            )}
          </div>

          {/* Right sidebar for word tips and editing */}
          <div className="lg:w-[30%] space-y-4 lg:sticky lg:top-4 lg:self-start lg:max-h-[calc(100vh-2rem)] lg:overflow-y-auto">
            <WordTipsPanel
              selectedWord={selectedWord}
              noteId={noteId}
              selectedPage={selectedPage}
              setSelectedWord={setSelectedWord}
              setSelectedPage={setSelectedPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordNoteContent;