import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';

const PageItem = ({ index, title, isSelected, onClick, onDelete, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [error, setError] = useState('');
  const maxLength = 50;

  const handleTitleClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value.slice(0, maxLength);
    setEditedTitle(newTitle);
    if (newTitle.length === maxLength) {
      setError(`Page title cannot exceed ${maxLength} characters.`);
    } else {
      setError('');
    }
  };

  const handleTitleSubmit = (e) => {
    e.preventDefault();
    onEdit(editedTitle);
    setIsEditing(false);
  };

  return (
    <div 
      className={`group bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 transform hover:scale-102 cursor-pointer flex items-center justify-between mb-2 ${
        isSelected ? 'ring-2 ring-blue-500 scale-102' : ''
      } w-[95%] mx-auto`}
      onClick={onClick}
    >
      <div className="p-2 flex-grow overflow-hidden flex items-center">
        <span className="text-gray-500 mr-2 font-medium">{index}.</span>
        {isEditing ? (
          <form onSubmit={handleTitleSubmit} onClick={(e) => e.stopPropagation()} className="flex-grow">
            <input
              type="text"
              value={editedTitle}
              onChange={handleTitleChange}
              className="w-full p-1 border rounded text-sm"
              autoFocus
              onBlur={handleTitleSubmit}
            />
          </form>
        ) : (
          <h3 
            className={`text-base font-medium ${isSelected ? 'text-blue-600' : 'text-gray-800'} group-hover:text-blue-600 transition-colors duration-300 font-poppins truncate`}
          >
            <span 
              onClick={handleTitleClick}
              className="cursor-text"
            >
              {title}
            </span>
          </h3>
        )}
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        className="text-red-500 hover:text-red-700 p-2 flex-shrink-0"
      >
        <Trash2 size={16} />
      </button>
    </div>
  );
};

export default PageItem;