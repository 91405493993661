import React, { useState } from 'react';
import { 
  Typography, Box, Button, Radio, RadioGroup, FormControlLabel, 
  FormControl, Alert, Card, CardContent
} from '@mui/material';

const GrammarModule = ({ data }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers(prev => ({...prev, [questionIndex]: value}));
  };

  const handleSubmit = () => {
    setShowFeedback(true);
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>Grammar</Typography>
      
      {/* Display all grammar rules as cards */}
      {data.map((grammarPoint, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>{grammarPoint.rule.chinese}</Typography>
            <Typography variant="body2" color="textSecondary" paragraph>{grammarPoint.rule.english}</Typography>
            <Typography variant="body1" paragraph>
              Examples:
              <ul>
                {grammarPoint.examples.map((example, exIndex) => (
                  <li key={exIndex}>{example}</li>
                ))}
              </ul>
            </Typography>
            <Typography variant="body2" color="error" paragraph>
              Common Mistake: {grammarPoint.commonMistakes.chinese}
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              {grammarPoint.commonMistakes.english}
            </Typography>
          </CardContent>
        </Card>
      ))}

      {/* Display all exercises */}
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>Exercises</Typography>
      {data.map((grammarPoint, index) => (
        <Box key={index} mt={4}>
          <Typography variant="subtitle1" gutterBottom>
            {index + 1}. {grammarPoint.exercise.question}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              value={selectedAnswers[index] || ''}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
            >
              {grammarPoint.exercise.options.map((option, optIndex) => (
                <FormControlLabel
                  key={optIndex}
                  value={optIndex.toString()}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {showFeedback && (
            <Alert 
              severity={selectedAnswers[index] === grammarPoint.exercise.correctAnswer.toString() ? "success" : "error"} 
              sx={{ mt: 2 }}
            >
              {selectedAnswers[index] === grammarPoint.exercise.correctAnswer.toString()
                ? 'Correct!' 
                : `Incorrect. The correct answer is: ${grammarPoint.exercise.options[grammarPoint.exercise.correctAnswer]}`
              }
            </Alert>
          )}
        </Box>
      ))}

      {/* Single submit button for all questions */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 4 }}
      >
        Submit All Answers
      </Button>
    </>
  );
};

export default GrammarModule;