import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../UserContext';
import GameLogic from './GameLogic';
import GameStartScreen from './GameStartScreen';
import EndGameScreen from './EndGameScreen';
import WarmUp from './WarmUp';

const preloadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(url);
    img.onerror = () => reject(url);
    img.src = url;
  });
};

const Game = () => {
  const { user } = useUser();
  const { category: urlCategory, subCategory: urlSubCategory } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [category, setCategory] = useState(urlCategory);
  const [subCategory, setSubCategory] = useState(urlSubCategory);
  const [gameState, setGameState] = useState({
    currentQuestion: 0,
    score: 0,
    timeLeft: 200,
    gameOver: false,
    userAnswers: [],
    inputValue: '',
    combo: 0,
    showAnswer: false,
    isCorrect: null,
    showHint: false,
    showConfetti: false,
    finalResults: null,
    currentLevel: 1,
    questions: [],
    totalLevels: 3,
    hasNextLevel: true,
    error: null,
    currentWordMedia: null,
    isPaused: false,
    gameStarted: false,
    isLoading: true,
    preloadedMedia: {},
  });
  const [userProgress, setUserProgress] = useState([]);
  const [showWarmUp, setShowWarmUp] = useState(false);

  const updateGameState = useCallback((updates) => {
    setGameState((prevState) => ({ ...prevState, ...updates }));
  }, []);

  useEffect(() => {
    // Update category and subCategory if they change in the URL
    setCategory(urlCategory);
    setSubCategory(urlSubCategory);
  }, [urlCategory, urlSubCategory]);

  useEffect(() => {
    // Check if we have category and subCategory from state (passed during navigation)
    if (location.state?.category && location.state?.subCategory) {
      setCategory(location.state.category);
      setSubCategory(location.state.subCategory);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchUserProgress = async () => {
      if (!user?.id) {
        console.error('User ID is not available');
        return;
      }
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`/api/word-challenge/user-progress?user_id=${user.id}&category_id=${category}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserProgress(response.data);
      } catch (error) {
        console.error('Error fetching user progress:', error);
      }
    };

    if (user?.id) {
      fetchUserProgress();
    }
  }, [user?.id, category]);

  const updateUserProgress = useCallback(async () => {
    if (!user?.id) {
      console.error('User ID is not available');
      return;
    }
    const token = localStorage.getItem('token');
    try {
      await axios.post('/api/word-challenge/update-progress', {
        user_id: user.id,
        category_id: category,
        level: gameState.currentLevel,
        stars: gameState.finalResults.stars,
        score: gameState.finalResults.score,
        completed: true
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error updating user progress:', error);
    }
  }, [user?.id, category, gameState.currentLevel, gameState.finalResults]);

  useEffect(() => {
    if (gameState.gameOver && gameState.finalResults && user?.id) {
      updateUserProgress();
    }
  }, [gameState.gameOver, gameState.finalResults, updateUserProgress, user?.id]);

  const handleStartGame = (selectedLevel) => {
    updateGameState({
      currentLevel: selectedLevel,
      gameStarted: true,
      gameOver: false,
      currentQuestion: 0,
      score: 0,
      timeLeft: 200,
      userAnswers: [],
      inputValue: '',
      combo: 0,
      showAnswer: false,
      isCorrect: null,
      showHint: false,
      finalResults: null,
      isLoading: true,
    });
    fetchQuestions(selectedLevel);
  };

  const fetchQuestions = useCallback((level) => {
    updateGameState({ isLoading: true });
    const token = localStorage.getItem('token');
    axios.get(`/api/word-challenge?category_id=${category}&level=${level}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(async (response) => {
        if (response.data && Array.isArray(response.data.words)) {
          const words = response.data.words;
          const mediaPromises = words.map(word => 
            axios.get(`/api/word-challenge/media?word_id=${word.id}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
          );

          const mediaResponses = await Promise.all(mediaPromises);
          const preloadedMedia = {};

          for (let i = 0; i < words.length; i++) {
            const wordId = words[i].id;
            const mediaData = mediaResponses[i].data;
            const imageUrl = mediaData.image_url;
            const audioUrl = mediaData.audio_url;

            await preloadImage(imageUrl);
            preloadedMedia[wordId] = { image_url: imageUrl, audio_url: audioUrl };
          }

          updateGameState({
            questions: words,
            totalLevels: response.data.total_levels || 3,
            hasNextLevel: response.data.has_next_level,
            currentQuestion: 0,
            userAnswers: [],
            inputValue: '',
            showAnswer: false,
            isCorrect: null,
            timeLeft: 200,
            error: null,
            preloadedMedia,
            isLoading: false,
          });
          setShowWarmUp(true);
        } else {
          throw new Error("Invalid data structure received from API");
        }
      })
      .catch(error => {
        console.error('Error fetching questions:', error);
        updateGameState({ 
          error: "Failed to load questions. Please try again later.",
          isLoading: false
        });
      });
  }, [category, updateGameState]);

  const handleReplay = () => {
    handleStartGame(gameState.currentLevel);
  };

  const handleExit = () => {
    navigate('/word-challenge');
  };

  

  if (!gameState.gameStarted) {
    return (
      <GameStartScreen
        onStartGame={handleStartGame}
        category={category}
        subCategory={subCategory}
        userProgress={userProgress}
      />
    );
  }

  if (showWarmUp) {
    return (
      <WarmUp
        words={gameState.questions}
        preloadedMedia={gameState.preloadedMedia}
        onStartChallenge={() => setShowWarmUp(false)}
      />
    );
  }

  if (gameState.error) {
    return (
      <div className="container mx-auto p-4 font-inter">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
        <p>{gameState.error}</p>
        <button 
          onClick={() => navigate('/word-challenge')}
          className="mt-4 bg-gradient-to-r from-blue-500 to-teal-500 text-white px-6 py-2 rounded-full hover:from-blue-600 hover:to-teal-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
        >
          Return to Categories
        </button>
      </div>
    );
  }

  if (gameState.gameOver && gameState.finalResults) {
    return (
      <EndGameScreen
        level={gameState.currentLevel}
        score={gameState.finalResults.score}
        stars={gameState.finalResults.stars}
        onNext={(nextLevel) => handleStartGame(nextLevel)}
        onRetry={(currentLevel) => handleStartGame(currentLevel)}
        onExit={() => navigate('/word-challenge')}
        maxLevel={gameState.totalLevels}
        gameState={gameState}
        categoryId={category}
      />
    );
  }
  return (
    <GameLogic
      gameState={gameState}
      updateGameState={updateGameState}
      category={category}
      subCategory={subCategory}
      navigate={navigate}
      onReplay={handleReplay}
      onExit={handleExit}
    />
  );
};

export default Game;