import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa';
import { IoMenuOutline } from "react-icons/io5";
import '../../fonts.css';
import api from '../../../utils/api';

// Helper function to adjust color brightness
function adjustColor(color, amount) {
  if (!color) return '#3B82F6'; // Default to a blue color if no color is provided
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

const NoteCard = ({ id, title, lastModified, themeColor, onDelete, onEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const defaultColor = '#4A90E2';
  const cardColor = themeColor || defaultColor;
  
  const lightenColor = (color, percent) => {
    const num = parseInt(color.replace("#",""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = (num >> 8 & 0x00FF) + amt,
      B = (num & 0x0000FF) + amt;
    return `#${(0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1)}`;
  };

  const lighterColor = lightenColor(cardColor, 20);

  return (
    <div className="group relative w-full aspect-[4/5] perspective-500 cursor-pointer mx-auto">
      <div className="absolute inset-0 bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105">
        {/* White background container */}
        <div className="absolute inset-0 bg-white p-4">
          {/* Existing notecard design */}
          <div className="relative w-full h-full">
            {/* Spiral binding */}
            <div className="absolute left-0 top-0 bottom-0 w-4 bg-gray-300 flex flex-col justify-around items-center">
              {[...Array(10)].map((_, i) => (
                <div key={i} className="w-3 h-3 rounded-full bg-white shadow-inner"></div>
              ))}
            </div>

            {/* Notebook cover with gradient */}
            <div 
              className="absolute inset-0 ml-4 rounded-r-lg" 
              style={{ 
                background: `linear-gradient(135deg, ${cardColor} 0%, ${lighterColor} 100%)` 
              }}
            >
              {/* Content */}
              <div className="relative z-30 p-4 flex flex-col h-full">
                <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-sm rounded-lg p-3 shadow-sm">
                  <h3 className="text-sm font-bold text-white font-sans tracking-wide break-words line-clamp-3">{title}</h3>
                </div>
                <div className="flex-grow"></div>
                <p className="text-xs text-white font-sans mt-2 opacity-80">Last modified: {new Date(lastModified).toLocaleDateString()}</p>
              </div>
            </div>

            {/* Pen */}
            <div className="absolute bottom-4 right-4 w-2 h-20 bg-yellow-400 transform rotate-45 rounded-t-full"></div>
            <div className="absolute bottom-4 right-4 w-3 h-3 bg-gray-700 transform rotate-45 rounded-full"></div>
          </div>
        </div>

        {/* Menu button */}
        <button
          className="absolute top-2 right-2 p-2 bg-white bg-opacity-20 backdrop-filter backdrop-blur-sm rounded-full shadow-md hover:bg-opacity-30 focus:outline-none z-40 transition-all duration-300"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          <IoMenuOutline size={24} color={cardColor} />
        </button>

        {/* Expanded options */}
        {isExpanded && (
          <div className="absolute top-12 right-2 bg-white shadow-md rounded-md p-2 z-50">
            <button
              className="flex items-center w-full p-2 hover:bg-gray-100 text-left"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEdit(id);
                setIsExpanded(false);
              }}
            >
              <FaEdit className="mr-2" style={{ color: cardColor }} /> Edit
            </button>
            <button
              className="flex items-center w-full p-2 hover:bg-gray-100 text-left text-red-600"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete(id);
                setIsExpanded(false);
              }}
            >
              <FaTrash className="mr-2" /> Delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const CreateNoteCard = ({ onClick }) => (
  <div className="group relative w-full aspect-[4/5] perspective-500 cursor-pointer mx-auto" onClick={onClick}>
    <div className="absolute inset-0 bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105">
      {/* Modern pattern overlay */}
      <div className="absolute inset-0 opacity-10 bg-[radial-gradient(circle,#f0f0f0_1px,transparent_1px)] bg-[size:20px_20px]"></div>
      
      {/* Glossy effect */}
      <div className="absolute inset-0 bg-gradient-to-b from-white opacity-20 rounded-t-xl h-1/3"></div>
      
      {/* Content */}
      <div className="relative z-30 p-6 flex flex-col items-center justify-center h-full">
        <div className="w-14 h-14 rounded-full bg-blue-500 flex items-center justify-center mb-4">
          <FaPlus className="h-5 w-5 text-white" />
        </div>
        <h3 className="text-base font-semibold text-gray-800 group-hover:text-blue-600 transition-colors duration-300 font-poppins text-center">Create New Note</h3>
      </div>
    </div>
  </div>
);

const CreateNoteModal = ({ isOpen, onClose, onCreate, onUpdate, editingNote }) => {
  const [title, setTitle] = useState('');
  const [themeColor, setThemeColor] = useState('#3B82F6');
  const recommendedColors = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899', '#14B8A6', '#F97316', '#6366F1'];

  // Use useEffect to update state when editingNote changes
  useEffect(() => {
    if (editingNote) {
      setTitle(editingNote.title);
      setThemeColor(editingNote.themeColor);
    } else {
      setTitle('');
      setThemeColor('#3B82F6');
    }
  }, [editingNote]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingNote) {
      onUpdate(title, themeColor);
    } else {
      onCreate(title, themeColor);
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-base font-bold mb-4">{editingNote ? 'Edit Note' : 'Create New Notebook'}</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="title">
              Notebook Name
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-xs font-bold mb-2" htmlFor="themeColor">
              Theme Color
            </label>
            <input
              type="color"
              id="themeColor"
              value={themeColor}
              onChange={(e) => setThemeColor(e.target.value)}
              className="shadow appearance-none border rounded w-full h-10 cursor-pointer"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-xs font-bold mb-2">
              Recommended Colors
            </label>
            <div className="flex flex-wrap gap-2">
              {recommendedColors.map((color) => (
                <button
                  key={color}
                  type="button"
                  className="w-8 h-8 rounded-full cursor-pointer"
                  style={{ backgroundColor: color }}
                  onClick={() => setThemeColor(color)}
                ></button>
              ))}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              {editingNote ? 'Save' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const WordNotesList = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingNote, setEditingNote] = useState(null);

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const response = await api.get('/api/word-notes');
      setNotes(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching notes:', error);
      setError('Failed to fetch notes. Please try again.');
      setLoading(false);
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/signin');
      }
    }
  };

  const handleCreateNote = async (title, themeColor) => {
    try {
      const response = await api.post('/api/word-notes', { title, themeColor });
      // Navigate to the note with a query parameter to indicate focusing on the first page
      navigate(`/word-notes/${response.data.id}?focusFirstPage=true`);
    } catch (error) {
      console.error('Error creating note:', error);
      setError('Failed to create note. Please try again.');
      if (error.response && error.response.status === 401) {
        navigate('/signin');
      }
    }
  };

  const handleDeleteNote = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await api.delete(`/api/word-notes/${noteId}`);
        setNotes(notes.filter(note => note.id !== noteId));
      } catch (error) {
        console.error('Error deleting note:', error);
        setError('Failed to delete note. Please try again.');
        if (error.response && error.response.status === 401) {
          navigate('/signin');
        }
      }
    }
  };

  const handleEditNote = (noteId) => {
    const noteToEdit = notes.find(note => note.id === noteId);
    setEditingNote(noteToEdit);
    setIsModalOpen(true);
  };

  const handleUpdateNote = async (title, themeColor) => {
    try {
      const response = await api.put(`/api/word-notes/${editingNote.id}`, { title, themeColor });
      setNotes(notes.map(note => note.id === editingNote.id ? response.data : note));
      setEditingNote(null);
    } catch (error) {
      console.error('Error updating note:', error);
      setError('Failed to update note. Please try again.');
      if (error.response && error.response.status === 401) {
        navigate('/signin');
      }
    }
  };

  if (loading) {
    return <div>Loading notes...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="bg-gray-50 p-6 rounded-lg font-inter">
      <section>
        <h2 className="text-2xl font-bold text-gray-900 font-poppins mb-6">Your Word Notes</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
          <div className="w-full max-w-[240px] mx-auto">
            <CreateNoteCard onClick={() => {
              setEditingNote(null);
              setIsModalOpen(true);
            }} />
          </div>
          {notes.map((note) => (
            <div key={note.id} className="w-full max-w-[240px] mx-auto">
              <Link to={`/word-notes/${note.id}`} className="no-underline">
                <NoteCard
                  id={note.id}
                  title={note.title}
                  lastModified={note.lastModified}
                  themeColor={note.themeColor}
                  onDelete={handleDeleteNote}
                  onEdit={handleEditNote}
                />
              </Link>
            </div>
          ))}
        </div>
      </section>
      <CreateNoteModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingNote(null);
        }}
        onCreate={handleCreateNote}
        onUpdate={handleUpdateNote}
        editingNote={editingNote}
      />
    </div>
  );
};

export default WordNotesList;