import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="min-h-screen bg-blue-50 font-sans">
      <header className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold text-red-600">Fifteenpenny.com</div>
          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center space-x-4 lg:space-x-6">
            <a href="#" className="text-blue-600 hover:text-blue-800">Home</a>
            <a href="#" className="text-blue-600 hover:text-blue-800">Services</a>
            <a href="#" className="text-blue-600 hover:text-blue-800">Contacts</a>
            <Link to="/signin" className="bg-red-600 text-white px-4 py-2 lg:px-6 lg:py-2 rounded-full hover:bg-red-700 transition duration-300">
              Sign In
            </Link>
          </nav>
          {/* Mobile menu button */}
          <button className="md:hidden text-blue-600" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <nav className="mt-4 md:hidden">
            <a href="#" className="block py-2 text-blue-600 hover:text-blue-800">Home</a>
            <a href="#" className="block py-2 text-blue-600 hover:text-blue-800">Services</a>
            <a href="#" className="block py-2 text-blue-600 hover:text-blue-800">Contacts</a>
            <Link to="/signin" className="mt-2 w-full bg-red-600 text-white px-4 py-2 rounded-full hover:bg-red-700 transition duration-300 block text-center">
              Sign In
            </Link>
          </nav>
        )}
      </header>

      <main className="container mx-auto px-4 py-8 md:py-12 flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 md:pr-8 lg:pr-16 mb-8 md:mb-0">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-blue-600 mb-4 md:mb-6">
            Master English with AI-Powered Learning
          </h1>
          <p className="text-lg sm:text-xl text-gray-600 mb-6 md:mb-8">
            Unlock your potential with our intuitive, personalized, and engaging English learning app. Learn faster and smarter!
          </p>
          <Link to="/signup" className="w-full sm:w-auto bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300 inline-block text-center">
            Start Learning Now
          </Link>
        </div>
        <div className="w-full md:w-1/2 relative">
          <div className="bg-white rounded-3xl shadow-lg p-4 sm:p-8 relative z-10">
            <img src="https://dvlsyid0zdgx1.cloudfront.net/assets/images/cover_landing_page_new.png" alt="People learning together" className="rounded-2xl mb-4 w-full h-auto" />
          </div>
          {/* Decorative elements */}
          <div className="absolute top-0 right-0 w-12 h-12 sm:w-16 sm:h-16 bg-blue-200 rounded-full -mr-6 -mt-6 sm:-mr-8 sm:-mt-8"></div>
          <div className="absolute bottom-0 left-0 w-8 h-8 sm:w-12 sm:h-12 bg-green-200 rounded-full -ml-4 -mb-4 sm:-ml-6 sm:-mb-6"></div>
          <div className="absolute top-1/4 right-1/4 w-6 h-6 sm:w-8 sm:h-8 bg-yellow-200 rounded-full"></div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;