import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validateUsername = (username) => {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(username);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!validateUsername(username)) {
      setError('Username can only contain English characters, numbers, underscore (_) and hyphen (-).');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post('/api/auth/signup', { username, email, password, invite_code: inviteCode });
      setMessage(response.data.message);
      setIsVerificationSent(true);
    } catch (err) {
      setError(err.response?.data?.message || 'Error creating account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const verified = params.get('verified');
    if (verified === 'true') {
      setIsVerified(true);
      setMessage('Email verified successfully. Your account is now active.');
    } else if (verified === 'false') {
      setError('Email verification failed. Please try signing up again.');
    }
  }, [location]);

  if (isVerified) {
    return (
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Account Verified</h2>
        <p className="mb-4">{message}</p>
        <Link to="/signin" className="text-blue-500">
          Click here to sign in
        </Link>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen">
      <div className="hidden md:block md:w-2/5 bg-blue-100 relative overflow-hidden">
        <img 
          src="https://dvlsyid0zdgx1.cloudfront.net/assets/images/cover_signin_new.png" 
          alt="Illustration" 
          className="absolute inset-0 w-full h-full object-cover object-center"
        />
      </div>
      <div className="w-full md:w-3/5 flex items-center justify-center bg-white">
        <div className="w-full max-w-md p-8">
          <div className="flex justify-between items-center mb-8">
            <Link to="/signin" className="text-blue-500 text-sm">Already have an account? Sign in</Link>
          </div>
          <h1 className="text-3xl font-bold mb-8">Sign up</h1>
          {isVerificationSent ? (
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-4">Verification Email Sent</h2>
              <p className="mb-4">Please check your email to verify your account.</p>
              <p className="text-sm text-gray-600">
                If you don't see the email, please check your spam folder.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="username" className="block text-gray-700 mb-2">Username</label>
                <input
                  type="text"
                  id="username"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 relative">
                <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md pr-10"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 mt-8"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="mb-6">
                <label htmlFor="inviteCode" className="block text-gray-700 mb-2">Invite Code</label>
                <input
                  type="text"
                  id="inviteCode"
                  className="w-full px-3 py-2 bg-gray-100 rounded-md"
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                  required
                />
              </div>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              {message && <p className="text-green-500 mb-4">{message}</p>}
              <button
                type="submit"
                className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Sending...
                  </>
                ) : (
                  'Sign up'
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;