import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthenticatedRoute = ({ element: Element, requireAuth, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('token');

  if (requireAuth && !isAuthenticated) {
    // Redirect to signin page if not authenticated
    return <Navigate to="/signin" replace />;
  }

  return <Element {...rest} />;
};

export default AuthenticatedRoute;