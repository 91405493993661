import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const RecentScore = () => {
  // Mock data for recent 7 days scores with weights
  const scoreData = [
    { day: 'Day 1', score: 85 },
    { day: 'Day 2', score: 88 },
    { day: 'Day 3', score: 92 },
    { day: 'Day 4', score: 87 },
    { day: 'Day 5', score: 90 },
    { day: 'Day 6', score: 93 },
    { day: 'Day 7', score: 91 },
  ];

  const averageScore = scoreData.reduce((acc, day) => acc + day.score, 0) / scoreData.length;

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Recent 7 Days Score</h2>
      <div className="text-5xl font-bold text-blue-600 mb-4">
        {averageScore.toFixed(1)}
      </div>
      <p className="text-gray-600 mb-4">Weighted average of all abilities</p>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={scoreData}>
          <XAxis dataKey="day" />
          <YAxis domain={['dataMin - 5', 'dataMax + 5']} />
          <Tooltip />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Line type="monotone" dataKey="score" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RecentScore;
