import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import api from '../utils/api';

import './fonts.css';

const LessonCard = ({ image_url, name, id }) => (
  <Link to={`/lesson/scenario/${id}`} className="group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105 cursor-pointer">
    <div 
      className="h-48 bg-gray-200 bg-cover bg-center"
      style={{backgroundImage: `url(${encodeURI(image_url)})`}}
    ></div>
    <div className="p-4 bg-white">
      <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600 transition-colors duration-300 font-poppins">{name}</h3>
    </div>
  </Link>
);

const CategoryButton = ({ image, name, id }) => (
  <Link to={`/word-challenge/${id}/game`} className="group flex flex-col items-center justify-center bg-white rounded-lg p-6 hover:bg-gray-50 transition-all duration-300 shadow-sm hover:shadow-md transform hover:-translate-y-1">
    <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mb-3 group-hover:bg-blue-200 transition-colors duration-300">
      <img src={image} alt={name} className="w-10 h-10 object-cover" />
    </div>
    <span className="text-sm font-medium text-center text-gray-700 group-hover:text-blue-600 transition-colors duration-300 font-inter">{name}</span>
  </Link>
);

const getInitial = (name) => {
  return name.charAt(0).toUpperCase();
};

const getColorForType = (type) => {
  return type === 'word_challenge' ? 'bg-blue-500' : 'bg-green-500';
};

const RecentLearningCard = ({ title, progress, type, id, scenarioId }) => {
  const initial = getInitial(title);
  const bgColor = getColorForType(type);

  const linkTo = type === 'word_challenge' 
    ? `/word-challenge/${id}/game` 
    : `/lesson/scenario/${scenarioId}`;

  return (
    <Link to={linkTo} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
      <div className="flex items-center mb-3">
        <div className={`w-12 h-12 rounded-full ${bgColor} mr-4 flex items-center justify-center`}>
          <span className="text-white font-bold text-xl">{initial}</span>
        </div>
        <h4 className="text-lg font-semibold text-gray-800 font-poppins">{title}</h4>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
        <div className="bg-gradient-to-r from-blue-500 to-teal-500 h-2.5 rounded-full transition-all duration-500 ease-out" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-sm font-medium text-blue-600 font-inter">{progress}% complete</p>
        <span className="text-sm font-medium text-gray-600 hover:text-blue-600 transition-colors duration-300 font-inter">Continue</span>
      </div>
    </Link>
  );
};

const SectionHeader = ({ title, moreLink }) => (
  <div className="flex justify-between items-center mb-6">
    <h2 className="text-3xl font-bold text-gray-900 font-poppins">{title}</h2>
  </div>
);

const MoreButton = ({ to }) => (
  <Link
    to={to}
    className="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
  >
    <ChevronRight className="w-6 h-6 text-blue-600" />
  </Link>
);

const WordChallengeCard = ({ title, description, imageUrl, to }) => (
  <Link to={to} className="group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105 cursor-pointer">
    <div 
      className="h-80 bg-gray-200 bg-cover bg-center"
      style={{backgroundImage: `url(${encodeURI(imageUrl)})`}}
    ></div>
    <div className="p-6 bg-white">
      <h3 className="text-2xl font-semibold text-gray-800 group-hover:text-blue-600 transition-colors duration-300 font-poppins mb-2">{title}</h3>
      <p className="text-base text-gray-600 font-inter">{description}</p>
    </div>
  </Link>
);

const HomePage = () => {
  const [lessons, setLessons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [recentLearning, setRecentLearning] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [lessonsResponse, categoriesResponse, recentLearningResponse] = await Promise.all([
          api.get('/api/scenarios'),
          api.get('/api/word-challenge/categories'),
          api.get('/api/user/recent-learning')
        ]);

        setLessons(lessonsResponse.data.slice(0, 4));
        setCategories(categoriesResponse.data.slice(0, 4));
        setRecentLearning(recentLearningResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          // Token is invalid or expired, redirect to login
          localStorage.removeItem('token');
          navigate('/login');
        }
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <div className="space-y-12 bg-gray-50 p-8 rounded-lg font-inter">
      <section>
        <SectionHeader title="Words" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
          <WordChallengeCard 
            title="Word Challenge" 
            imageUrl="https://dvlsyid0zdgx1.cloudfront.net/assets/images/cover_word_challenge.png"
            to="/word-challenge" 
          />
          <WordChallengeCard 
            title="Word Notes" 
            imageUrl="https://dvlsyid0zdgx1.cloudfront.net/assets/images/cover_word_note.png"
            to="/word-notes" 
          />
        </div>
      </section>

      <section>
        <SectionHeader title="Featured Lessons" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 relative">
          {lessons.map((lesson, index) => (
            <LessonCard key={index} {...lesson} id={lesson.id || `S58ba47f${index}`} />
          ))}
          <div className="absolute -right-6 top-1/2 transform -translate-y-1/2">
            <MoreButton to="/lesson" />
          </div>
        </div>
      </section>

      <section>
        <h2 className="text-3xl font-bold mb-6 text-gray-900 font-poppins">Recent Learning</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {recentLearning.length > 0 ? (
            recentLearning.map((activity, index) => (
              <RecentLearningCard key={index} {...activity} />
            ))
          ) : (
            <p className="text-gray-600">No recent learning activities found.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default HomePage;