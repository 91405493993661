import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, Typography, Box, Card, CardContent, 
  LinearProgress, Grid, CardActionArea, Button,
  Stepper, Step, StepLabel, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VocabModule from './VocabModule';
import GrammarModule from './GrammarModule';
import ListeningModule from './ListeningModule';
import TranslationModule from './TranslationModule';
import { useUser } from '../UserContext';

import '../fonts.css';
import axios from 'axios';

const SubScenarioCard = ({ name, author, progress, onClick, isSelected, orderNum }) => (
  <div 
    onClick={onClick}
    className={`group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105 cursor-pointer flex mb-4 ${
      isSelected ? 'ring-2 ring-blue-500 scale-105' : ''
    } max-w-[90%] mx-auto`}
  >
    <div className="bg-gray-100 flex items-center justify-center px-4 font-bold text-gray-500 font-poppins">
      {orderNum}.
    </div>
    <div className="p-4 flex-grow flex flex-col justify-between">
      <div>
        <h3 className={`text-lg font-semibold ${isSelected ? 'text-blue-600' : 'text-gray-800'} group-hover:text-blue-600 transition-colors duration-300 font-poppins`}>{name}</h3>
        <p className="text-sm text-gray-600 font-inter">{author}</p>
      </div>
      <div className="mt-2">
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-1">
          <div 
            className="bg-gradient-to-r from-blue-500 to-teal-500 h-2.5 rounded-full transition-all duration-500 ease-out" 
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="text-xs text-right text-gray-600 font-inter">{progress}% complete</p>
      </div>
    </div>
  </div>
);

const ModuleButton = ({ id, title, isActive, isCompleted, onClick }) => (
  <button 
    onClick={() => onClick(id)}
    className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
      isActive 
        ? 'bg-blue-600 text-white' 
        : isCompleted
          ? 'bg-green-100 text-green-800'
          : 'bg-gray-100 text-gray-800'
    } hover:shadow-md transform hover:-translate-y-1 font-inter`}
  >
    {title}
  </button>
);

const ModuleContent = ({ type, data }) => {
    const moduleComponents = {
      vocab: VocabModule,
      grammar: GrammarModule,
      listening: ListeningModule,
      translation: TranslationModule
    };
  
    const ModuleComponent = moduleComponents[type];
  
    return (
      <div className="bg-white rounded-xl p-6 shadow-md">
        {ModuleComponent ? (
          <ModuleComponent data={data} />
        ) : (
          <p className="font-inter">Content for {type} module is not available.</p>
        )}
      </div>
    );
  };

const ScenarioPage = () => {
  const { scenarioId } = useParams();
  const [scenario, setScenario] = useState(null);
  const [subScenarios, setSubScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubScenario, setSelectedSubScenario] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const [currentModule, setCurrentModule] = useState('vocab');
  const [completedModules, setCompletedModules] = useState([]);
  const [userProgress, setUserProgress] = useState({});
  const [isLessonLoading, setIsLessonLoading] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const [cachedLessonData, setCachedLessonData] = useState({});

  const modules = [
    { id: 'vocab', title: 'Vocabulary & Phrases' },
    { id: 'grammar', title: 'Grammar' },
    { id: 'listening', title: 'Listening' },
    { id: 'translation', title: 'Translation' }
  ];

  useEffect(() => {
    fetchScenario(scenarioId);
  }, [scenarioId]);

  useEffect(() => {
    if (scenario && subScenarios.length > 0) {
      fetchAllSubScenarioProgress();
      // Automatically select the first sub-scenario
      setSelectedSubScenario(subScenarios[0]);
    }
  }, [scenario, subScenarios]);

  useEffect(() => {
    if (selectedSubScenario && !cachedLessonData[selectedSubScenario.id]) {
      fetchLessonData(selectedSubScenario.id);
    } else if (selectedSubScenario) {
      setLessonData(cachedLessonData[selectedSubScenario.id]);
    }
    setCompletedModules(userProgress[selectedSubScenario?.id] || []);
  }, [selectedSubScenario, cachedLessonData, userProgress]);

  const fetchScenario = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/scenarios/${id}`);
      setScenario(response.data);
      setSubScenarios(response.data.sub_scenarios);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching scenario:', error);
      setLoading(false);
    }
  };

  const fetchAllSubScenarioProgress = async () => {
    const token = localStorage.getItem('token');
    const progressPromises = subScenarios.map(subScenario =>
      axios.get(`/api/progress/${subScenario.id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
    );

    try {
      const progressResults = await Promise.all(progressPromises);
      const newUserProgress = {};
      subScenarios.forEach((subScenario, index) => {
        newUserProgress[subScenario.id] = Object.keys(progressResults[index].data).filter(
          module => progressResults[index].data[module]
        );
      });
      setUserProgress(newUserProgress);
    } catch (error) {
      console.error('Error fetching user progress:', error);
    }
  };

  const fetchLessonData = async (subScenarioId) => {
    setIsLessonLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/sub-scenarios/${subScenarioId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCachedLessonData(prev => ({
        ...prev,
        [subScenarioId]: response.data.lesson
      }));
      setLessonData(response.data.lesson);
    } catch (error) {
      console.error('Error fetching lesson data:', error);
    } finally {
      setIsLessonLoading(false);
    }
  };

  const handleSubScenarioSelect = (subScenario) => {
    setSelectedSubScenario(subScenario);
    setCurrentModule('vocab'); // Reset to the first module
  };

  const handleModuleClick = (moduleId) => {
    if (completedModules.includes(moduleId) || completedModules.includes(modules[modules.findIndex(m => m.id === moduleId) - 1]?.id)) {
      setCurrentModule(moduleId);
    }
  };

  const handleModuleComplete = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/progress', {
        sub_scenario_id: selectedSubScenario.id,
        module: currentModule
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      setUserProgress(prev => {
        const updatedModules = [...new Set([...(prev[selectedSubScenario.id] || []), currentModule])];
        return {
          ...prev,
          [selectedSubScenario.id]: updatedModules.slice(0, 4)
        };
      });

      const moduleOrder = ['vocab', 'grammar', 'listening', 'translation'];
      const currentIndex = moduleOrder.indexOf(currentModule);
      
      // Move to the next module if it exists, otherwise stay on the last module
      if (currentIndex < moduleOrder.length - 1) {
        setCurrentModule(moduleOrder[currentIndex + 1]);
      }
    } catch (error) {
      console.error('Error recording progress:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen py-4 px-2 sm:px-4">
    <div className="max-w-[95%] mx-auto">
      <div className="flex flex-col lg:flex-row gap-4 lg:gap-6">
        <div className="lg:w-[30%] space-y-3 lg:sticky lg:top-4 lg:self-start lg:max-h-[calc(100vh-2rem)] lg:overflow-y-auto">
          {scenario && (
            <div className="bg-white rounded-xl overflow-hidden shadow-md mb-4">
              <div className="p-4">
                <button 
                  onClick={() => navigate('/lesson')} 
                  className="text-blue-600 mb-2 hover:underline font-inter"
                >
                  ← Back to Scenarios
                </button>
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-2 font-poppins">{scenario.name}</h1>
              </div>
              <div className="flex justify-center items-center p-4">
                <div className="aspect-square w-56 h-56 relative overflow-hidden rounded-lg">
                  <img 
                    src={scenario.image_url || "https://via.placeholder.com/300x300"} 
                    alt={scenario.name} 
                    className="absolute w-[120%] h-[120%] object-cover left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              </div>
            </div>
          )}
            <h2 className="text-lg sm:text-xl font-semibold mb-2 text-gray-800 font-poppins">Sub-scenarios</h2>
            {subScenarios.map((subScenario, index) => (
              <SubScenarioCard
                key={subScenario.id}
                {...subScenario}
                progress={calculateProgress(userProgress[subScenario.id] || [])}
                onClick={() => handleSubScenarioSelect(subScenario)}
                isSelected={selectedSubScenario?.id === subScenario.id}
                orderNum={index + 1}
              />
            ))}
          </div>
          <div className="lg:w-[70%] space-y-4">
            {selectedSubScenario && (isLessonLoading ? (
              <div className="bg-white rounded-xl p-4 sm:p-6 shadow-md">
                <p className="text-base sm:text-lg text-center text-gray-600 font-inter">
                  Loading lesson data...
                </p>
              </div>
            ) : lessonData ? (
              <>
                <h2 className="text-lg sm:text-xl font-semibold mb-2 text-gray-800 font-poppins">{selectedSubScenario.name}</h2>
                <div className="flex flex-wrap gap-2 mb-3">
                  {modules.map((module) => (
                    <ModuleButton
                      key={module.id}
                      {...module}
                      isActive={currentModule === module.id}
                      isCompleted={completedModules.includes(module.id)}
                      onClick={handleModuleClick}
                    />
                  ))}
                </div>
                <ModuleContent 
                  type={currentModule} 
                  data={
                    currentModule === 'vocab' ? lessonData.vocabulary :
                    currentModule === 'grammar' ? lessonData.grammar :
                    currentModule === 'listening' ? lessonData.listeningExercise :
                    currentModule === 'translation' ? lessonData.translationExercises :
                    null
                  } 
                />
                <button 
                  onClick={handleModuleComplete}
                  className="mt-4 bg-gradient-to-r from-blue-500 to-teal-500 text-white text-base sm:text-lg font-semibold px-6 py-2 rounded-full hover:from-blue-600 hover:to-teal-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1 font-poppins w-full"
                >
                  Complete Module
                </button>
              </>
            ) : (
              <div className="bg-white rounded-xl p-4 sm:p-6 shadow-md">
                <p className="text-base sm:text-lg text-center text-gray-600 font-inter">
                  Please select a sub-scenario to start the lesson.
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper function to calculate progress percentage
const calculateProgress = (completedModules) => {
  return Math.min(Math.round((completedModules.length / 4) * 100), 100);
};

export default ScenarioPage;