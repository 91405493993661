import React, { useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaMagic, FaBookReader, FaBookOpen, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';

const TipItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TipLabel = styled.h4`
  font-medium text-gray-700;
`;

const TipContent = styled.p`
  text-gray-600;
`;

const GeneratingTips = styled.div`
  padding: 1rem;
`;

const WordTipItemComponent = ({ label, tip }) => (
  <TipItem>
    <TipLabel>{label}:</TipLabel>
    <TipContent>{tip}</TipContent>
  </TipItem>
);

const WordInfoPanel = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ExamplesList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 1rem;
`;

const ExampleItem = styled.li`
  margin-bottom: 0.5rem;
  font-style: italic;
`;

const HighlightedWord = styled.span`
  font-weight: bold;
  color: #4a90e2;
`;

const WordInfoDisplay = ({ word }) => {
  const translations = Array.isArray(word.translation)
    ? word.translation
    : word.translation.split(',');

  return (
    <WordInfoPanel>
      <h3 className="text-xl font-semibold mb-2">{word.word}</h3>
      <p className="text-gray-600">
        {translations.map((translation, index) => (
          <span key={index}>
            {translation}
            <br />
          </span>
        ))}
      </p>
      {word.examples && word.examples.length > 0 && (
        <>
          <h4 className="text-lg font-semibold mt-4 mb-2">Examples:</h4>
          <ExamplesList>
            {word.examples.slice(0, 3).map((example, index) => (
              <ExampleItem key={index}>
                {example.split(' ').map((w, i) => 
                  w.toLowerCase().startsWith(word.word.toLowerCase()) ? (
                    <HighlightedWord key={i}>{w} </HighlightedWord>
                  ) : (
                    `${w} `
                  )
                )}
              </ExampleItem>
            ))}
          </ExamplesList>
        </>
      )}
    </WordInfoPanel>
  );
};

const MagicButton = styled.button`
  background-color: transparent;
  color: #4a90e2;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;

  &:hover {
    background-color: rgba(74, 144, 226, 0.1);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const TipsPanel = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const WordTipsDisplay = ({ word, onGenerateTips }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const [remainingGenerations, setRemainingGenerations] = useState(null);
  const [showInfoBubble, setShowInfoBubble] = useState(true);

  const handleGenerateTips = async () => {
    setIsGenerating(true);
    try {
      const result = await onGenerateTips();
      if (result.remainingGenerations !== undefined) {
        setRemainingGenerations(result.remainingGenerations);
        setShowInfoBubble(true);
        if (result.remainingGenerations === 0) {
          toast.warning("You've reached the daily limit for generating tips.");
        }
      }
    } catch (error) {
      console.error('Error generating tips:', error);
      if (error.response && error.response.status === 429) {
        toast.warning("You've reached the daily limit for generating tips.");
        setRemainingGenerations(0);
        setShowInfoBubble(true);
      } else {
        toast.error('Failed to generate tips. Please try again later.');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <TipsPanel>
      <TitleContainer>
        <h3 className="text-xl font-semibold">Tips</h3>
        {(!word.tips || Object.keys(word.tips).length === 0) && (
          <MagicButton 
            onClick={handleGenerateTips} 
            disabled={isGenerating}
          >
            <FaMagic size={16} />
          </MagicButton>
        )}
      </TitleContainer>
      {remainingGenerations !== null && remainingGenerations < 2 && showInfoBubble && (
        <InfoBubble>
          <p className="text-sm">
            {remainingGenerations === 0
              ? "You've reached the daily limit for generating tips."
              : `You have ${remainingGenerations} tip generation${remainingGenerations === 1 ? '' : 's'} left today.`}
          </p>
          <CloseButton onClick={() => setShowInfoBubble(false)}>
            <FaTimes size={12} />
          </CloseButton>
        </InfoBubble>
      )}
      {isGenerating ? (
        <GeneratingTips>
          <p className="text-gray-600 italic mb-2">Generating tips...</p>
          <ShimmerEffect />
          <ShimmerEffect />
          <ShimmerEffect />
        </GeneratingTips>
      ) : word.tips && Object.keys(word.tips).length > 0 ? (
        <>
          {word.tips.english && (
            <WordTipItemComponent label="English Tip" tip={word.tips.english} />
          )}
          {word.tips.chinese && (
            <WordTipItemComponent label="Chinese Tip" tip={word.tips.chinese} />
          )}
          {word.tips.breakdown && (
            <WordTipItemComponent label="Breakdown" tip={word.tips.breakdown} />
          )}
          {word.tips.story && (
            <>
              <StoryButton onClick={() => setShowStory(!showStory)}>
                {showStory ? <FaBookReader /> : <FaBookOpen />}
                <span>{showStory ? 'Hide Story' : 'Show Story'}</span>
              </StoryButton>
              {showStory && (
                <WordTipItemComponent label="Story" tip={word.tips.story} />
              )}
            </>
          )}
        </>
      ) : (
        <p className="text-gray-600 text-center">No tips available for this word. Click the magic wand to generate tips.</p>
      )}
    </TipsPanel>
  );
};

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
`;

const StoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
  box-shadow: 0 4px 15px rgba(110, 142, 251, 0.4);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, transparent 70%);
    transform: scale(0);
    transition: transform 0.5s ease-out;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(110, 142, 251, 0.6);

    &:before {
      transform: scale(1);
    }
  }

  svg {
    margin-right: 0.75rem;
    font-size: 1.2em;
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

const InfoBubble = styled.div`
  position: relative;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 8px 32px 8px 12px;
  margin-bottom: 12px;
  font-size: 0.875rem;
  color: #856404;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #856404;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #533f03;
  }
`;

const WordTipsPanel = ({ selectedWord, noteId, selectedPage, setSelectedWord, setSelectedPage }) => {
  const handleGenerateTips = useCallback(async () => {
    if (!selectedWord || !noteId || !selectedPage) return;

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `/api/word-notes/${noteId}/pages/${selectedPage.id}/words/${selectedWord.id}/generate-tips`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      // Update the word in the selectedPage state
      setSelectedPage(prev => ({
        ...prev,
        words: prev.words.map(word => 
          word.id === selectedWord.id ? { ...word, ...response.data } : word
        )
      }));

      // Update the selectedWord
      setSelectedWord(prev => ({ ...prev, ...response.data }));

      return response.data;
    } catch (error) {
      console.error('Error generating tips:', error);
      // Handle error (e.g., show an error message to the user)
      throw error;
    }
  }, [noteId, selectedPage, selectedWord, setSelectedPage, setSelectedWord]);

  if (!selectedWord) {
    return (
      <div className="bg-white rounded-xl p-4 sm:p-6 shadow-md">
        <p className="text-base sm:text-lg text-center text-gray-600 font-inter">
          Select a word to view its information and tips.
        </p>
      </div>
    );
  }

  return (
    <>
      <WordInfoDisplay word={selectedWord} />
      <WordTipsDisplay word={selectedWord} onGenerateTips={handleGenerateTips} />
    </>
  );
};

export default WordTipsPanel;