// File: src/components/Record/StudyActivity.js
import React from 'react';

const StudyActivity = ({ selectedYear, setSelectedYear }) => {
  const generateActivityData = (year) => {
    const data = [];
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      data.push({
        date: new Date(currentDate),
        activity: Math.floor(Math.random() * 5)
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return data;
  };

  const activityData = generateActivityData(selectedYear);

  const getMonthLabel = (date) => {
    return date.toLocaleString('default', { month: 'short' });
  };

  const getActivityColor = (activity) => {
    const colors = ['bg-gray-200', 'bg-green-200', 'bg-green-300', 'bg-green-400', 'bg-green-500'];
    return colors[activity];
  };

  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  // Generate an array of 53 weeks (max possible in a year)
  const weeks = Array.from({ length: 53 }, (_, weekIndex) => {
    return Array.from({ length: 7 }, (_, dayIndex) => {
      const dataIndex = weekIndex * 7 + dayIndex;
      return activityData[dataIndex] || null;
    });
  });

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Study Activity</h2>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
          className="border rounded p-1"
        >
          {[2020, 2021, 2022, 2023].map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>
      <div className="flex">
        <div className="mr-2">
          {dayNames.map((day, index) => (
            <div key={index} className="h-4 text-xs text-gray-400 leading-4">{day}</div>
          ))}
        </div>
        <div className="flex-1 overflow-x-auto">
          <div className="flex" style={{ minWidth: 'max-content' }}>
            {weeks.map((week, weekIndex) => (
              <div key={weekIndex} className="mr-1">
                {weekIndex % 4 === 0 && (
                  <div className="text-xs text-gray-400 mb-1 text-center h-4 leading-4">
                    {week[0] ? getMonthLabel(week[0].date) : ''}
                  </div>
                )}
                {weekIndex % 4 !== 0 && <div className="h-4"></div>}
                <div className="flex flex-col">
                  {week.map((day, dayIndex) => (
                    <div
                      key={dayIndex}
                      className={`w-4 h-4 mb-1 rounded-sm ${day ? getActivityColor(day.activity) : 'bg-gray-100'}`}
                      title={day ? `${day.date.toDateString()}: ${day.activity} contributions` : ''}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyActivity;