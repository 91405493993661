import React, { useState } from 'react';
import { 
  Typography, Box, Button, TextField, Alert, Card, CardContent,
  List, ListItem, ListItemText, IconButton, Tooltip
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ProgressNode = ({ number, isActive, onClick, isLast }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box 
      onClick={onClick}
      sx={{
        width: 24,
        height: 24,
        border: '2px solid',
        borderColor: isActive ? 'primary.main' : 'text.secondary',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: isActive ? 'primary.main' : 'text.secondary',
        fontWeight: 'bold',
        fontSize: '0.8rem',
      }}
    >
      {number}
    </Box>
    {!isLast && (
      <Box 
        sx={{ 
          width: 16, 
          height: 2, 
          backgroundColor: 'text.secondary',
          mx: 0.5,
        }} 
      />
    )}
  </Box>
);

const TranslationModule = ({ data }) => {
  const [currentSentence, setCurrentSentence] = useState(0);
  const [userTranslation, setUserTranslation] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [showHints, setShowHints] = useState(false);

  const handleSubmit = () => {
    setShowFeedback(true);
  };

  const goToSentence = (index) => {
    setCurrentSentence(index);
    setUserTranslation('');
    setShowFeedback(false);
  };

  const toggleHints = () => {
    setShowHints(!showHints);
  };

  const goToNextSentence = () => {
    if (currentSentence < data.length - 1) {
      setCurrentSentence(currentSentence + 1);
      setUserTranslation('');
      setShowFeedback(false);
      setShowHints(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        {data.map((_, index) => (
          <ProgressNode
            key={index}
            number={index + 1}
            isActive={index === currentSentence}
            onClick={() => goToSentence(index)}
            isLast={index === data.length - 1}
          />
        ))}
      </Box>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>{data[currentSentence].chinese}</Typography>
            <Tooltip title={showHints ? "Hide Hints" : "Show Hints"}>
              <IconButton
                onClick={toggleHints}
                sx={{
                  color: showHints ? 'primary.main' : 'action.active',
                  ml: 1,
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={userTranslation}
            onChange={(e) => setUserTranslation(e.target.value)}
            placeholder="Enter your translation"
            margin="normal"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={goToNextSentence}
              disabled={currentSentence === data.length - 1}
            >
              Next
            </Button>
          </Box>
          {showFeedback && (
            <Alert severity="info" sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>Reference translation:</Typography>
              <Typography variant="body1">{data[currentSentence].english}</Typography>
            </Alert>
          )}
          {showHints && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>Hints:</Typography>
              <Typography variant="body2">Sentence structure: {data[currentSentence].hints.sentence}</Typography>
              <List dense>
                {data[currentSentence].hints.keywords.map((keyword, index) => (
                  <ListItem key={index}>
                    <ListItemText 
                      primary={`${keyword.english} - ${keyword.chinese}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default TranslationModule;